import { getFileSize, getFileExtension } from '../fileUtils'
import filesize from 'filesize'
import { FILE_MAX_SIZE, TEXT_FIELD_MAX_SIZE } from '../../appConst'
import { isNull, isValidEmail, isPositiveInt, isPositiveDigit } from './constraints'

export const validateEmailFields = (fieldNames) => (values) => (
    fieldNames.reduce((errors, fieldName) => {
        if (!isNull(values[fieldName]) && !isValidEmail(values[fieldName])) {
            return {
                ...errors,
                [fieldName]: {
                    id: 'validation.notValidEmail'
                }
            }
        }
        return errors
    }, {})
)

export const validateMandatoryFields = (fieldNames) => (values = {}) => (
    fieldNames.reduce((errors, fieldName) => {
        if (isNull(values[fieldName])) {
            return {
                ...errors,
                [fieldName]: {
                    id: 'validation.mandatory'
                }
            }
        }
        return errors
    }, {})
)

export const validateFileMaxSize = (fieldNames, maxSize = FILE_MAX_SIZE) => (values) => (
    fieldNames.reduce((errors, fieldName) => {
        if (getFileSize(values[fieldName]) > maxSize) {
            const [formattedMaxSize, suffix] = filesize(maxSize, { output: 'array' })
            return {
                ...errors,
                [fieldName]: {
                    id: 'validation.file.max_size',
                    values: {
                        maxSize: formattedMaxSize,
                        suffix
                    }
                }
            }
        }
        return errors
    }, {})
)

export const validateFileExtension = (fieldNames, extensions = []) => (values) => (
    fieldNames.reduce((errors, fieldName) => {
        if (values[fieldName] instanceof Array || values[fieldName] instanceof FileList) {
            for (let i = 0; i < values[fieldName].length; i++) {
                const fileExtension = getFileExtension(values[fieldName][i])
                if (extensions.findIndex(extension => extension.toUpperCase() === fileExtension.toUpperCase()) < 0) {
                    return {
                        ...errors,
                        [fieldName]: {
                            id: 'validation.file.extension'
                        }
                    }
                }
            }
        }
        return errors
    }, {})
)

export const validatePositiveDigitFields = (fieldNames, includeZero) => (values) => (
    fieldNames.reduce((errors, fieldName) => {
        if (!isPositiveDigit(values[fieldName], includeZero)) {
            return {
                ...errors,
                [fieldName]: {
                    id: 'validation.number.positive'
                }
            }
        }
        return errors
    }, {})
)

export const validateNumberRange = (fieldNames, min, max) => (values) => (
    fieldNames.reduce((errors, fieldName) => {
        if (!isNaN(values[fieldName]) && (values[fieldName] < min || values[fieldName] > max)) {
            return {
                ...errors,
                [fieldName]: {
                    id: 'validation.number.range',
                    values: { min, max }
                }
            }
        }
        return errors
    }, {})
)

export const validatePositiveIntFields = (fieldNames, includeZero) => (values) => (
    fieldNames.reduce((errors, fieldName) => {
        if (!isPositiveInt(values[fieldName], includeZero)) {
            return {
                ...errors,
                [fieldName]: {
                    id: 'validation.number.int_positive'
                }
            }
        }
        return errors
    }, {})
)

export const combineValidators = (validators) => (values) => (
    validators.reduce((errors, validator) => {
        // TODO exclure les validations des fields qui sont déjà en erreur
        return {
            ...validator(values),
            ...errors
        }
    }, {})
)

/**
 * Valider une valeur avec un ensemble de contraintes
 * @param value une valeur à valider
 * @param constraints un ensemble d'utilitaires regex pour validation
 * @returns {*}
 */
export const checkWithConstraints = (value, constraints) => {
    for (let constraint = 0; constraint < constraints.length; ++constraint) {
        const error = constraints[constraint](value)
        if (error) {
            return error
        }
    }
}

export const validateStringFieldLength = (fieldNames, maxSize = TEXT_FIELD_MAX_SIZE) => (values) => (
    fieldNames.reduce((errors, fieldName) => {
        if(!isNull(values[fieldName]) && values[fieldName].toString().length > maxSize){
            return {
                ...errors,
                [fieldName]: {
                    id: 'validation.max_length',
                    values: { nb: maxSize }
                }
            }
        }
        return errors
    }, {})
)
