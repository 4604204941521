import React, { FC, Fragment } from 'react'
import InformationDialog from '../../components/InformationDialog'
import { FormattedMessage } from 'react-intl'
import { AppImportError } from '../../utils/import-error-converter'

type ImportErrorModalProps = {
	open: boolean
	close: () => void
	error: AppImportError
}

const ImportErrorModal: FC<ImportErrorModalProps> = ({
	open,
	close,
	error
}) => {
	if (!open) {
		return (<></>)
	}
	return (
		<InformationDialog
			open
			maxWidth="lg"
			title={<FormattedMessage id="compensation_claim.details.request_file.upload.error.dialog.title" />}
			onClose={close}
			declineLabel={<FormattedMessage id="compensation_claim.details.request_file.upload.error.dialog.action" />}
		>
			<Fragment>
				<FormattedMessage id="compensation_claim.details.request_file.upload.error.dialog.body.information" />
				<br /><br />
				{error.globalError && <FormattedMessage {...error.globalError} />}
				{
					error.sheetErrors.map((error, index) => (
						<li key={index}>
							<FormattedMessage
								id={error.sheet ? "compensation_claim.details.request_file.upload.error.dialog.body.details" : "compensation_claim.details.request_file.upload.error.dialog.body.details_no_sheet"}
								values={{
									sheet_name: error.sheet,
									n_row: error.row,
									message: error.message
								}}
							/>
						</li>
					))
				}
			</Fragment>
		</InformationDialog>
	)
}

export default ImportErrorModal
