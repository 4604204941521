import { fetchFactory } from 'isotope-client'

const API_PREFIX = '/api/public'

export const checkTokenValid = (email, token) => fetchFactory(`/password/${email}?token=${token}`, {}, API_PREFIX)

export const changePassword = (token, email, values) => fetchFactory(`/password/${email}?token=${token}`, {
    method: 'POST',
    body: JSON.stringify(values)
}, API_PREFIX)
