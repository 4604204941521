const _compensationClaimSelector = state => state.app.compensationClaim.claim
const _compensationSummariesSelector = state => state.app.compensationClaim.summaries

export const compensationClaimSelector = state => _compensationClaimSelector(state).value
export const compensationClaimLoadingSelector = state => _compensationClaimSelector(state).loading
export const compensationClaimErrorSelector = state => _compensationClaimSelector(state).error

export const compensationSummariesMapSelector = state => _compensationSummariesSelector(state).value
export const compensationSummariesLoadingSelector = state => _compensationSummariesSelector(state).loading
export const compensationSummariesErrorSelector = state => _compensationSummariesSelector(state).error
