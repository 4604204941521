/**
 * Vérifier si le string est vide
 * @param string
 */
export const isEmpty = (string) => {
    return string === null || string === undefined || string.trim().length === 0
}

/**
 * Vérifier si le string contient de "vrai" texte
 * @param string
 * @returns {boolean}
 * @see isEmpty
 */
export const hasText = (string) => {
    return !isEmpty(string)
}
