import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { compose, lifecycle, branch, renderNothing } from 'recompose'
import { createSelector } from 'reselect'
import { Paper, Grid, withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import { Form } from '../../components/redux-form'
import ChangePasswordFields from './ChangePasswordFields'
import PasswordConstraintsContext from '../../modules/password/PasswordConstraintsContext'
import { passwordConfigSelector } from '../../properties/selectors'
import {
    checkWithConstraints,
    digits,
    lowercase,
    specialChar,
    uppercase,
    minLength,
    validateMandatoryFields, noAccent
} from '../../utils/form'
import { changePassword, checkTokenValid } from './api'
import { snackError, snackSuccess } from '../../global/snackActions'
import Headline from '../../components/Headline'

const styles = (theme) => getStyles({
    root: {
        backgroundImage: theme.login.background,
        height: '100%',
        width: '100%',
        backgroundSize: 'cover',
        margin: 0
    },
    page: {
        marginTop: 12,
        marginBottom: 12
    },
    actions: {
        justifyContent: 'center'
    }
})

const validateMandatory = validateMandatoryFields(['password', 'confirmation'])
const validate = (values, props) => {
    const errors = validateMandatory(values)
    if (!errors.password) {
        const {
            minLength: minLen,
            nbMinUppercases,
            nbMinLowercases,
            nbMinNumbers,
            nbMinSpecialCharacters
        } = props
        errors.password = checkWithConstraints(values.password, [
            lowercase(nbMinLowercases),
            uppercase(nbMinUppercases),
            digits(nbMinNumbers),
            specialChar(nbMinSpecialCharacters),
            noAccent,
            minLength(minLen)
        ])
        if (!errors.password && !errors.confirmation && values.password !== values.confirmation) {
            errors.confirmation = { id: 'change_password.validation.confirmation' }
        }
    }
    return errors
}

const getToken = (props) => {
    const params = new URLSearchParams(props.location.search)
    return params.get('token')
}

class ChangePasswordPage extends Component {
    handleChangePassword = (values, dispatch) => {
        return changePassword(getToken(this.props), this.props.match.params.email, values)
            .then(() => {
                this.props.history.replace('/login')
                dispatch(snackSuccess(`login.${this.props.mode}_password.success`))
            }, (e) => {
                dispatch(snackError())
                throw new SubmissionError(e)
            })
    }
    render() {
        const {
            classes,
            mode,
            passwordConfig
        } = this.props
        return (
            <Grid container alignItems="center" className={classes.root} spacing={2}>
                <Grid item xs={1} md={5} lg={7} />
                <Grid item xs={12} md={6} lg={4}>
                    <Paper>
                        <Grid container alignItems="center" justify="center" spacing={2}>
                            <Grid item xs={1} />
                            <Grid item xs={10} className={classes.page}>
                                <Headline>
                                    <FormattedMessage id={`login.${mode}_password.title`} />
                                </Headline>
                                <Form
                                    form="ChangePasswordForm"
                                    validate={validate}
                                    onSubmit={this.handleChangePassword}
                                    showCancelButton={false}
                                    submitLabel={<FormattedMessage id={`login.${mode}_password.action`} />}
                                    { ...passwordConfig }
                                >
                                    <PasswordConstraintsContext.Provider value={passwordConfig}>
                                        <ChangePasswordFields />
                                    </PasswordConstraintsContext.Provider>
                                </Form>
                            </Grid>
                            <Grid item xs={1} />
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={1} />
            </Grid>
        )
    }
}

ChangePasswordPage.propTypes = {
    mode: PropTypes.oneOf(['init', 'reset']).isRequired
}

const mapStateToProps = createSelector([
    passwordConfigSelector
], (passwordConfig) => ({
    passwordConfig
}))

export default compose(
    lifecycle({
        componentDidMount() {
            this.setState({
                eligible: false
            })
            const token = getToken(this.props)
            if (!token || !this.props.match.params.email) {
                this.props.history.replace('/login')
            }
            checkTokenValid(this.props.match.params.email, token)
                .then((res) => {
                    this.setState({
                        eligible: !!res
                    })
                    if (!res) {
                        this.props.history.replace('/login')
                    }
                }, () => {
                    this.props.history.replace('/login')
                })
        }
    }),
    branch(props => !props.eligible, renderNothing),
    withStyles(styles),
    connect(mapStateToProps)
)(ChangePasswordPage)
