// global.d.ts

/**
 * Permet d'éviter la répétition de undefined/null à chaque champs potentiellement concerné
 * Permet également d'utiliser les gardes de types:
 * @See assertNotNullOrUndefined
 */
declare type Nullable<T> = T | undefined | null


// utils.ts

/**
 * Le type de retour permet à typescript de restreindre davantage le type de cette valeur

 * @param value une valeur de type T pouvant être null ou undefined
 * @returns La fonction retourne false si le paramètre vaut null ou undefined.
 */
export const assertNotNullOrUndefined = <T = any>(value: Nullable<T>): value is T => {
	return typeof value !== 'undefined' && value !== null
}
