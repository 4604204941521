import React, { PureComponent } from 'react'
import PageFragment from '../../components/PageFragment'
import { FormattedMessage } from 'react-intl'
import Headline from '../../components/Headline'
import { SpringDataTable } from 'isotope-client'
import PropTypes from 'prop-types'

const headers = [
    {
        key: 'user.empName',
        name: <FormattedMessage id="compensation_claim.emp_name"/>,
        sortable: true,
        render: (row) => row.user.empName
    }, {
        key: 'user.instCode',
        name: <FormattedMessage id="compensation_claim.institution_code"/>,
        sortable: true,
        render: (row) => row.user.instCode
    }, {
        key: 'status',
        name: <FormattedMessage id="compensation_claim.status"/>,
        render: (row) => <FormattedMessage id={`enums.COMPENSATION_CLAIM_STATUS.${row.status}`}/>
    }
]

class CompensationClaimsList extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            filters: {
                year: this.props.currentYear
            }
        }
    }

    render() {
        return (
            <PageFragment>
                <Headline>
                    <FormattedMessage id="compensation_claim.list.title"/>
                </Headline>
                <SpringDataTable
                    apiUrlPrefix="/api"
                    apiUrl="/compensation_claims/search"
                    nom="empList"
                    headers={headers}
                    filters={this.state.filters}
                    onRowClick={this.props.handleClick}
                />
            </PageFragment>
        )
    }
}

CompensationClaimsList.propTypes = {
    currentYear: PropTypes.number.isRequired,
    handleClick: PropTypes.func.isRequired
}

export default CompensationClaimsList
