import { isAdmin } from '../modules/user/rights'

export default [{
    text: 'compensation_claim',
    path: '/suivi_dossiers',
    icon: 'Folder'
}, {
    text: 'calculation',
    path: '/calcul',
    access: isAdmin,
    icon: 'PlayArrow'
}, {
    text: 'subsidy_files',
    path: '/donnees_subventions',
    access: isAdmin,
    icon: 'ViewColumn',
    borderBottom: true
}, {
    text: 'users',
    path: '/utilisateurs',
    access: isAdmin,
    icon: 'Group',
    borderBottom: true
}, {
    text: 'parameters',
    path: '/parametres',
    access: isAdmin,
    icon: 'Settings',
    borderBottom: true
}]
