import React, { createContext, FC, ReactNode, useMemo } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { yearsSelector } from './selectors'
import PageFragment from '../../components/PageFragment'
import UnknownErrorFragment from '../../components/UnknownErrorFragment'
import Loader from '../../components/Loader'
import { LoadingValueWithError } from '../../global'
import { CalculationYear } from './types'
import {CALCULATION_STATUS} from "../../appConst";

type YearContextType = {
	calculationYears: CalculationYear[],
	availableYearsWithParameters: CalculationYear[],
	currentYearWithParameters: number
	currentYear: number
}

// Année par default, ne sera jamais utilisé au runtime en pratique une fois l'application initialisée
const defaultYear = 2022

export const YearContext = createContext<YearContextType>({
	calculationYears: [],
	currentYear: defaultYear,
	availableYearsWithParameters: [],
	currentYearWithParameters: defaultYear,
})

type ContextProps = {
	children?: ReactNode
	years: LoadingValueWithError<CalculationYear[]>
}

const YearContextProvider: FC<ContextProps> = ({ children, years }) => {
	const contextValue = useMemo<YearContextType>(() => {
		if (years.loading || years.error || years.value.length === 0) {
			return {
				calculationYears: [],
				availableYearsWithParameters: [],
				currentYearWithParameters: defaultYear,
				currentYear: defaultYear
			}
		}
		const currentYear = years.value[years.value.length - 1].year
		const availableYearsWithParameters = years.value.filter(y => y.status > CALCULATION_STATUS.PARAMETERS_INIT)
		const currentYearWithParameters = availableYearsWithParameters[availableYearsWithParameters.length - 1].year
		return {
			calculationYears: years.value,
			currentYear,
			availableYearsWithParameters,
			currentYearWithParameters
		}
	}, [years])

	if (years.error) {
		return (
			<PageFragment>
				<UnknownErrorFragment/>
			</PageFragment>
		)
	}
	if (years.loading) {
		return (
			<PageFragment>
				<Loader/>
			</PageFragment>
		)
	}

	return (
		<YearContext.Provider value={contextValue}>
			{children}
		</YearContext.Provider>
	)
}


const mapStateToProps = createSelector([yearsSelector], (years) => ({ years }))

export default connect(mapStateToProps)(YearContextProvider)
