import { Button } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import Headline from '../../../components/Headline'
import PageFragment from '../../../components/PageFragment'
import { isOperatorSelector } from '../../user/selectors'
import { findCompensationClaimByYear, findMyCompensationClaimByYear } from '../actions'

import { compensationClaimSelector } from '../selectors'
import CompensationClaimsDetails from './CompensationClaimDetails'
import CompensationClaimsYearFilter from './CompensationClaimYearFilter'
import {FILTER_MODE} from "../../../appConst";

export const COMPENSATION_CLAIM_FILTERS_NAME = 'CompensationClaimYearFilter'

class CompensationClaimDetailsPage extends PureComponent {

    handleSearchByYear = ({ year }) => {
        const {
            isOperator,
            ownerId
        } = this.props
        if (isOperator) {
            this.props.findMyCompensationClaimByYear(year)
        } else {
            this.props.findCompensationClaimByYear(year, ownerId)
        }
    }

    render() {
        const {
            initialFilter,
            handleBackButton,
            isOperator
        } = this.props
        return (
            <PageFragment>
                { !isOperator &&
                    <Button
                        variant="text"
                        startIcon={<ArrowBack />}
                        color="primary"
                        onClick={handleBackButton}
                    >
                        <FormattedMessage id="compensation_claim.details.back_button" />
                    </Button>
                }
                <Headline>
                    <FormattedMessage id="compensation_claim.details.title"/>
                </Headline>
                <CompensationClaimsYearFilter
                    form={COMPENSATION_CLAIM_FILTERS_NAME}
                    onSubmit={this.handleSearchByYear}
                    initialValues={initialFilter}
                    mode={FILTER_MODE.WITH_FILTER}
                />
                <CompensationClaimsDetails />
            </PageFragment>
        )
    }
}

CompensationClaimDetailsPage.propTypes = {
    currentYear: PropTypes.number.isRequired,
    handleBackButton: PropTypes.func.isRequired
}

const makeMapStateToProps = () => {
    const compensationClaimOwnerId = createSelector([
        compensationClaimSelector
    ], (compensationClaim) => {
        return compensationClaim && compensationClaim.user.id
    })
    return createSelector([
        isOperatorSelector,
        compensationClaimOwnerId,
        (state, props) => props.currentYear
    ], (isOperator, ownerId, year) => ({
        isOperator,
        ownerId,
        initialFilter: { year }
    }))
}

const mappedActions = {
    findCompensationClaimByYear,
    findMyCompensationClaimByYear
}

export default connect(
    makeMapStateToProps,
    mappedActions
)(CompensationClaimDetailsPage)
