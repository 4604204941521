import { combineReducers } from 'redux'
import {
    GET_COMPENSATION_CLAIM,
    GET_COMPENSATION_CLAIM_ERROR,
    GET_COMPENSATION_CLAIM_LOADING, GET_COMPENSATION_SUMMARIES,
    UPDATE_COMPENSATION_CLAIM
} from './actions'
import createReducer from '../../middlewares/api/createReducer'

const loading = (state = false, action) => {
    switch (action.type) {
        case GET_COMPENSATION_CLAIM_LOADING:
            return true
        case GET_COMPENSATION_CLAIM:
        case GET_COMPENSATION_CLAIM_ERROR:
            return false
        default:
            return state
    }
}
const error = (state = false, action) => {
    switch (action.type) {
        case GET_COMPENSATION_CLAIM:
            return false
        case GET_COMPENSATION_CLAIM_ERROR:
            return true
        default:
            return state
    }
}
const value = (state = null, action) => {
    switch (action.type) {
        case GET_COMPENSATION_CLAIM:
            return action.payload
        case UPDATE_COMPENSATION_CLAIM:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

const claim = combineReducers({
    loading,
    error,
    value
})

const summaries = createReducer(GET_COMPENSATION_SUMMARIES, {})

export default combineReducers({
    claim,
    summaries
})

