import React from 'react'
import PropTypes from 'prop-types'
import { HourglassEmpty } from '@material-ui/icons'
import { getStyles } from 'isotope-client'
import { withStyles } from '@material-ui/core'

const styles = theme => getStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.grey[500]
    }
})

const NoFileFragment = ({ classes, message }) => {
    return (
        <div className={classes.container}>
            <HourglassEmpty fontSize="large"/>
            {message}
        </div>
    )
}

NoFileFragment.propTypes = {
    message: PropTypes.node.isRequired
}

export default withStyles(styles)(NoFileFragment)
