import { Grid, InputAdornment } from '@material-ui/core'
import { FormInput } from 'isotope-client'
import React, { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Field } from 'redux-form'
import { PARAMETERS_TYPE } from '../../../appConst'
import Headline from '../../../components/Headline'
import { OutlinedFormInput } from '../../../components/redux-form'
import Form from '../../../components/redux-form/Form'
import Subtitle from '../../../components/Subtitle'
import { combineValidators, validateMandatoryFields, validateNumberRange } from '../../../utils/form'
import { handleSubmitAnimalParameters } from '../actions'
import CompensationFactors, { validate as validateCategory } from './CompensationFactors'

const SECTION_CERTIFICATE_COMPENSATION = 'certificateCompensation'
const SECTION_DISTRIBUTION_COMPENSATION = 'distributionCompensation'

const highRangeFields = [
    'total'
]
const intermediateRangeFields = [
    'compensationThreeYears'
]
const regularRangeFields = [
    'criteriaEligibilityDensity',
    'compensationIaDistributed',
    'compensationIaImplemented',
    'nbKmMin',
    'nbKmLimitEtp',
    'sectorLimitTechnician',
    'compensationRaceLocal',
    'compensationRaceSmallSize'
]
const smallRangeFields = [
    'groupMinSize',
    'ratioFromOverallBudget'
]
const percentageFields = [
    'percentageAxisTerritorial',
    'percentageAxisRacial',
    'criteriaEligibilityIchn',
    'pcMaxTurnoverCompensation'
]
const allFields = [
    ...highRangeFields,
    ...intermediateRangeFields,
    ...regularRangeFields,
    ...smallRangeFields,
    ...percentageFields
]

const validateMandatory = validateMandatoryFields(allFields)
const validateHighRanges = validateNumberRange(highRangeFields, 0, 99999999)
const validateIntermediateRangeFields = validateNumberRange(intermediateRangeFields, 0, 9999999)
const validateRegularRanges = validateNumberRange(regularRangeFields, 0, 9999)
const validateSmallRanges = validateNumberRange(smallRangeFields, 0, 1000)
const validatePercentages = validateNumberRange(percentageFields, 0, 100)
const _validate = combineValidators([
    validateMandatory,
    validateHighRanges,
    validateIntermediateRangeFields,
    validateRegularRanges,
    validateSmallRanges,
    validatePercentages
])
const validate = ({
                      [SECTION_CERTIFICATE_COMPENSATION]: certificateCompensation,
                      [SECTION_DISTRIBUTION_COMPENSATION]: distributionCompensation,
                      ...values
                  }) => {
    const errors = _validate(values)

    errors[SECTION_CERTIFICATE_COMPENSATION] = validateCategory(certificateCompensation || {})
    errors[SECTION_DISTRIBUTION_COMPENSATION] = validateCategory(distributionCompensation || {})

    return errors
}

const BovineParameters = ({ parameters, disabled }) => {
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = useCallback((values, dispatch) => {
        setSubmitting(true)
        return handleSubmitAnimalParameters(values)(dispatch).finally(() => {
            setSubmitting(false)
        })
    }, [setSubmitting])

    return (
        <Form
            form="bovineParametersForm"
            initialValues={parameters}
            enableReinitialize
            validate={validate}
            onSubmit={handleSubmit}
            submitting={submitting}
            showCancelButton={!disabled}
        >
            <Headline>
                <FormattedMessage id={`parameters.${PARAMETERS_TYPE.BOVINE}.title`} />
            </Headline>
            <Subtitle>
                <FormattedMessage id={`parameters.${PARAMETERS_TYPE.BOVINE}.general.title`} />
            </Subtitle>
            <Grid container>
                <Grid item xs={4}>
                    <Field
                        name="total"
                        label={<FormattedMessage id={`parameters.${PARAMETERS_TYPE.BOVINE}.general.total`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€</InputAdornment>
                        }}
                        min={0}
                        max={99999999}
                        step={0.01}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name="percentageAxisTerritorial"
                        label={<FormattedMessage
                            id={`parameters.${PARAMETERS_TYPE.BOVINE}.general.percentageAxisTerritorial`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        min={0}
                        max={100}
                        step={0.01}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name="percentageAxisRacial"
                        label={<FormattedMessage
                            id={`parameters.${PARAMETERS_TYPE.BOVINE}.general.percentageAxisRacial`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        min={0}
                        max={100}
                        step={0.01}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name="criteriaEligibilityDensity"
                        label={<FormattedMessage
                            id={`parameters.${PARAMETERS_TYPE.BOVINE}.general.criteriaEligibilityDensity`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">IA/km²</InputAdornment>
                        }}
                        min={0}
                        max={9999}
                        step={0.01}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name="criteriaEligibilityIchn"
                        label={<FormattedMessage
                            id={`parameters.${PARAMETERS_TYPE.BOVINE}.general.criteriaEligibilityIchn`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        min={0}
                        max={100}
                        step={0.01}
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
            <CompensationFactors
                species={PARAMETERS_TYPE.BOVINE}
                section={SECTION_CERTIFICATE_COMPENSATION}
                disabled={disabled}
            />
            <CompensationFactors
                species={PARAMETERS_TYPE.BOVINE}
                section={SECTION_DISTRIBUTION_COMPENSATION}
                disabled={disabled}
            />
            <Subtitle>
                <FormattedMessage id={`parameters.${PARAMETERS_TYPE.BOVINE}.compensationCalculation.title`} />
            </Subtitle>
            <Grid container>
                <Grid item xs={4}>
                    <Field
                        name="pcMaxTurnoverCompensation"
                        label={<FormattedMessage
                            id={`parameters.${PARAMETERS_TYPE.BOVINE}.compensationCalculation.pcMaxTurnoverCompensation`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        min={0}
                        max={100}
                        step={0.01}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name="compensationIaDistributed"
                        label={<FormattedMessage
                            id={`parameters.${PARAMETERS_TYPE.BOVINE}.compensationCalculation.compensationIaDistributed`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€/IA distribuées</InputAdornment>
                        }}
                        min={0}
                        max={9999}
                        step={0.01}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name="compensationIaImplemented"
                        label={<FormattedMessage
                            id={`parameters.${PARAMETERS_TYPE.BOVINE}.compensationCalculation.compensationIaImplemented`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€/IA mises en place</InputAdornment>
                        }}
                        min={0}
                        max={9999}
                        step={0.01}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name="nbKmMin"
                        label={<FormattedMessage
                            id={`parameters.${PARAMETERS_TYPE.BOVINE}.compensationCalculation.nbKmMin`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">km</InputAdornment>
                        }}
                        min={0}
                        max={9999}
                        step={1}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name="nbKmLimitEtp"
                        label={<FormattedMessage
                            id={`parameters.${PARAMETERS_TYPE.BOVINE}.compensationCalculation.nbKmLimitEtp`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">km</InputAdornment>
                        }}
                        min={0}
                        max={9999}
                        step={1}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name="sectorLimitTechnician"
                        label={<FormattedMessage
                            id={`parameters.${PARAMETERS_TYPE.BOVINE}.compensationCalculation.sectorLimitTechnician`} />}
                        component={OutlinedFormInput}
                        type="number"
                        min={0}
                        max={9999}
                        step={1}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name="compensationRaceLocal"
                        label={<FormattedMessage
                            id={`parameters.${PARAMETERS_TYPE.BOVINE}.compensationCalculation.compensationRaceLocal`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€</InputAdornment>
                        }}
                        min={0}
                        max={9999}
                        step={0.01}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name="compensationRaceSmallSize"
                        label={<FormattedMessage
                            id={`parameters.${PARAMETERS_TYPE.BOVINE}.compensationCalculation.compensationRaceSmallSize`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€</InputAdornment>
                        }}
                        min={0}
                        max={9999}
                        step={0.01}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name="compensationThreeYears"
                        label={<FormattedMessage
                            id={`parameters.${PARAMETERS_TYPE.BOVINE}.compensationCalculation.compensationThreeYears`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€</InputAdornment>
                        }}
                        min={0}
                        max={9999}
                        step={0.01}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name="groupMinSize"
                        label={<FormattedMessage
                            id={`parameters.${PARAMETERS_TYPE.BOVINE}.compensationCalculation.groupMinSize`} />}
                        component={FormInput}
                        type="number"
                        min={0}
                        max={1000}
                        step={0.01}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name="ratioFromOverallBudget"
                        label={<FormattedMessage
                            id={`parameters.${PARAMETERS_TYPE.BOVINE}.compensationCalculation.ratioFromOverallBudget`} />}
                        component={FormInput}
                        type="number"
                        min={0}
                        max={1000}
                        step={0.01}
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        </Form>
    )
}

export default BovineParameters
