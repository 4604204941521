import middle from '../../middlewares/api/middle'

const prefix = 'SUBSIDY_FILES/'

export const GET_SUBSIDY_FILES = `${prefix}GET_SUBSIDY_FILES`

export const getSubsidyFilesByYear = (year) => middle(GET_SUBSIDY_FILES, {
    api: '/api/admin',
    url: `/subsidy_files?year=${year}`
})
