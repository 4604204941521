import '@formatjs/intl-pluralrules/locale-data/fr'
import '@formatjs/intl-pluralrules/polyfill'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { ConnectedRouter, connectRouter, replace, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import {
    configureStorageMode,
    fetchUser as fetchUserApi,
    getLocalUser,
    getUser as getUserAction,
    logOut,
    messages as messagesIsotope,
    reducers as isotopeReducers,
    saveStore
} from 'isotope-client'
import React from 'react'
import ReactDOM from 'react-dom'
import { intlReducer, Provider } from 'react-intl-redux'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import Root from './containers/Root'
import messages from './i18n/messages'
import apiMiddleware from './middlewares/api/apiMiddleware'
import { getAppProperties } from './properties/api'
import propertiesReducer from './properties/reducer'
import appReducers from './reducers'
import rootSaga from './sagas'
import customTheme from './theme'

// Initialiser
// Faire sorte que le token soit stocké dans localStorage
configureStorageMode()

getAppProperties()
    .then((properties) => {
        const reactInit = (user) => {
            const formats = {
                date: {
                    datetime: {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                    }
                }
            }

            const initialState = {
                user,
                properties,
                app: {},
                intl: {
                    locale: 'fr',
                    messages: {
                        ...messagesIsotope,
                        ...messages
                    },
                    formats,
                    defaultLocale: 'fr',
                    defaultFormats: formats
                }
            }

            const sagaMiddleware = createSagaMiddleware()
            const history = createBrowserHistory()
            let enhancers
            if (process.env.NODE_ENV === 'production') {
                enhancers = [applyMiddleware(thunk, apiMiddleware, routerMiddleware(history), sagaMiddleware)]
            } else if (window.__REDUX_DEVTOOLS_EXTENSION__) {
                // utilisation de redux dev tools uniquement
                enhancers = [applyMiddleware(thunk, apiMiddleware, routerMiddleware(history), sagaMiddleware), window.__REDUX_DEVTOOLS_EXTENSION__()]
            } else {
                // à défaut, utilisation du redux-logger middleware
                // const loggerMiddleware = createLogger()
                enhancers = [applyMiddleware(thunk, apiMiddleware, routerMiddleware(history), sagaMiddleware)]
            }

            const rootReducer = combineReducers({
                // TODO EXTRAIRE UNIQUEMENT LES REDUCERS A UTILISER
                ...isotopeReducers,
                properties: propertiesReducer,
                app: appReducers,
                router: connectRouter(history),
                intl: intlReducer,
                form: formReducer
            })

            const store = createStore(rootReducer, initialState, compose(...enhancers))
            saveStore(store)

            // then run the saga
            const saga = sagaMiddleware.run(rootSaga)
            saga.toPromise()
                .catch(error => {
                    console.error('sagas stopped working', error)
                })

            store.dispatch(getUserAction(user))
            ReactDOM.render(
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <MuiThemeProvider theme={customTheme}>
                            <Root/>
                        </MuiThemeProvider>
                    </ConnectedRouter>
                </Provider>, document.getElementById('root'))

            // On gère la déconnexion multi-onglets pour le local storage
            window.addEventListener('storage', () => {
                const token = getLocalUser()
                if (token) {
                    fetchUserApi(token)
                        .then((user) => {
                            if (user) {
                                user.token = token
                                user.authenticated = true
                            }
                            store.dispatch(getUserAction(user))
                            store.dispatch(replace('/'))
                        }, (e) => {
                            console.error('ERROR', e)
                            store.dispatch(logOut())
                        })
                } else {
                    store.dispatch(logOut())
                }
            })
        }

        const token = getLocalUser()
        if (token) {
            fetchUserApi(token)
                .then((user) => {
                    if (user) {
                        user.token = token
                        user.authenticated = true
                    }
                    reactInit(user)
                }, (e) => {
                    console.error('ERROR', e)
                    reactInit()
                })
        } else {
            reactInit()
        }
    })
