import {Button, CircularProgress, Grid, Typography} from '@material-ui/core'
// @ts-ignore
import Errors from 'isotope-client/components/form/errors/Errors'
import React, {PropsWithChildren, ReactNode, useCallback} from 'react'
import {FormattedMessage} from 'react-intl'
import {reduxForm, ResetAction, SubmitAction} from 'redux-form'

/**
 * Composant de wrapper de formulaire avec un affichage de "Page"
 */

type FormProps = {
    submitLabel?: ReactNode
    cancelLabel?: ReactNode
    onCancel?: () => void
    showCancelButton?: boolean
    reset?: ResetAction
    submit?: SubmitAction
    manualSubmitting?: boolean
}

type CombinedFormProps<ErrorType> = FormProps & {
    // redux-form
    submitting: boolean
    pristine: boolean
    error: ErrorType
}

const Form = <FormType, ErrorType>({
    children,
    submitLabel,
    cancelLabel,
    onCancel,
    reset,
    manualSubmitting,
    showCancelButton = true,
    // redux-form
    submit,
    submitting,
    pristine,
    error
}: PropsWithChildren<CombinedFormProps<ErrorType>>) => {
    const handleCancel = useCallback(() => {
        if (onCancel) {
            onCancel()
        }
        if (reset) {
            reset()
        }
    }, [onCancel, reset])

    return (
        <div>
            <Errors errors={error} />
            {children}
            <Grid container justify="center" spacing={1}>
                {
                    showCancelButton && (
                        <Grid item>
                            <Button
                                onClick={handleCancel}
                                disabled={submitting || manualSubmitting}
                            >
                                <Typography variant="body1" color="primary">
                                    {cancelLabel || <FormattedMessage id="btn.cancel" />}
                                </Typography>
                            </Button>
                        </Grid>
                    )
                }
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={submit}
                        disabled={submitting || pristine || manualSubmitting}
                    >
                        { submitting || manualSubmitting ?
                            <CircularProgress color="primary" variant="indeterminate" size={18} /> :
                            <Typography variant="body1" color="inherit">
                                {submitLabel || <FormattedMessage id="btn.save" />}
                            </Typography>
                        }
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default reduxForm<any, PropsWithChildren<FormProps>>({})(Form)
