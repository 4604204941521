import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { compensationClaimLoadingSelector } from '../selectors'
import YearFilter from '../../../global/year/YearFilter'

const mapStateToProps = createSelector([
    compensationClaimLoadingSelector
], (loading) => ({
    disabled: loading
}))

export default connect(mapStateToProps)(YearFilter)
