import { withStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { pure, compose } from 'recompose'
import { getStyles } from 'isotope-client'

const styles = theme => getStyles({
    title: {
        marginBottom: theme.spacing(4)
    }
})

const Headline = ({
    className,
    classes,
    children,
    ...props
}) => {
    return (
        <Typography
            {...props}
            variant="h5"
            className={classnames(className, classes.title)}
        >{children}</Typography>
    )
}

Headline.propTypes = {
    children: PropTypes.node.isRequired,
    color: PropTypes.string,
    className: PropTypes.string
}

export default compose(
    pure,
    withStyles(styles)
)(Headline)
