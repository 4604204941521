import { createSelector } from 'reselect'
import { isAdmin, isOperator } from './rights'

export const connectedUserSelector = state => state.user

export const userAuthenticatedSelector = createSelector([
    connectedUserSelector
], (user) => user && user.authenticated)

export const userTokenSelector = createSelector([
    connectedUserSelector
], (user) => user && user.token)

export const isOperatorSelector = createSelector([
    connectedUserSelector
], (user) => isOperator(user))

export const isAdminSelector = createSelector([
    connectedUserSelector
], (user) => isAdmin(user))


