import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { compose, branch, renderComponent } from 'recompose'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { SubmissionError, reset } from 'redux-form'
import { createSelector } from 'reselect'
import ImportErrorModal from '../../../import/ImportErrorModal'
import UploadRequestFileForm from './UploadRequestFileForm'
import { isAdminSelector } from '../../../user/selectors'
import DownloadRequestFileBlock, { getDownloadHref } from './DownloadRequestFileBlock'
import NoFile from '../NoFileFragment'
import { COMPENSATION_CLAIM_STATUS } from '../../../../appConst'
import { uploadCompensationRequestFile } from '../../api'
import { UPDATE_COMPENSATION_CLAIM } from '../../actions'
import { snackError, snackSuccess } from '../../../../global/snackActions'
import { isFileExist } from '../../../../utils/fileUtils'
import importErrorConverter from '../../../../utils/import-error-converter'

const getFormName = (compensationClaim) => `UploadRequestFileForm${compensationClaim.id}`

class RequestFileBlock extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            openErrorDialog: false,
            error: null
        }
    }

    openErrorDialog = (error) => {
        this.setState({
            openErrorDialog: true,
            error
        })
    }

    closeErrorDialog = () => {
        this.setState({
            openErrorDialog: false
        })
        this.props.resetForm()
    }

    handleUploadFile = (values, dispatch, props) => {
        if (isFileExist(values.file)) {
            return uploadCompensationRequestFile(this.props.compensationClaim.id, values)
                .then((res) => {
                    dispatch({
                        type: UPDATE_COMPENSATION_CLAIM,
                        payload: res
                    })
                    dispatch(snackSuccess('compensation_claim.details.request_file.upload.success'))
                    props.reset()
                }, (e) => {
                    if (e.response.status === 422) {
                        this.openErrorDialog(importErrorConverter(e.bodyError))
                    } else {
                        dispatch(snackError())
                        throw new SubmissionError(e)
                    }
                })
        }
    }

    render() {
        const { compensationClaim } = this.props
        const { openErrorDialog, error } = this.state
        return (
            <Fragment>
                <UploadRequestFileForm
                    form={getFormName(compensationClaim)}
                    file={compensationClaim.requestFile}
                    downloadLink={getDownloadHref(compensationClaim.id)}
                    onSubmit={this.handleUploadFile}
                />
                <ImportErrorModal
                    open={openErrorDialog}
                    close={this.closeErrorDialog}
                    error={error}
                />
            </Fragment>
        )
    }
}

RequestFileBlock.propTypes = {
    compensationClaim: PropTypes.shape({
        id: PropTypes.string.isRequired,
        requestFile: PropTypes.object,
        status: PropTypes.number.isRequired
    }).isRequired
}

const mapStateToProps = createSelector([
    isAdminSelector
], (isAdmin) => ({
    isAdmin
}))

const mapDispatchToProps = (dispatch, props) => ({
    resetForm: () => dispatch(reset(getFormName(props.compensationClaim)))
})

const canOperatorDownloadFile = status =>
    status !== COMPENSATION_CLAIM_STATUS.FILE_AWAITING
    && status !== COMPENSATION_CLAIM_STATUS.FILE_TO_VALIDATE

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    branch(
        props => (canOperatorDownloadFile(props.compensationClaim.status) && !!props.compensationClaim.requestFile)
            || (props.isAdmin && !!props.compensationClaim.requestFile),
        renderComponent(DownloadRequestFileBlock)
    ),
    branch(
        props => props.isAdmin && !props.compensationClaim.requestFile,
        renderComponent(() => <NoFile
            message={<FormattedMessage id="compensation_claim.details.request_file.empty"/>}/>)
    )
)(RequestFileBlock)
