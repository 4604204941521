import { Grid, MenuItem } from '@material-ui/core'
import { FormSelect } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Field } from 'redux-form'
import { createSelector } from 'reselect'
import {FILTER_MODE, PARAMETERS_TYPE_VALUES} from '../../appConst'
import { reduxForm } from '../../components/redux-form'
import { yearsSelector } from '../../global/year/selectors'
import YearsSelect from '../../global/year/YearsSelect'
import { parametersLoadingSelector } from './selectors'

const ParametersFilters = ({ disabled }) => {
    return (
        <Grid container spacing={1}>
            <Grid item>
                <Field
                    name="year"
                    label={<FormattedMessage id="global.filters.year"/>}
                    component={YearsSelect}
                    disabled={disabled}
                    mode={FILTER_MODE.NO_FILTER}
                />
            </Grid>
            <Grid item>
                <Field
                    name="species"
                    label={<FormattedMessage id="global.filters.species"/>}
                    component={FormSelect}
                    disabled={disabled}
                >
                    {PARAMETERS_TYPE_VALUES.map(type => (
                        <MenuItem key={type} value={type}>
                            <FormattedMessage id={`enums.PARAMETERS_TYPE.${type}`}/>
                        </MenuItem>
                    ))}
                </Field>
            </Grid>
        </Grid>
    )
}

ParametersFilters.propTypes = {
    form: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired
}

const mapStateToProps = createSelector([
    parametersLoadingSelector,
    yearsSelector
], (parametersLoading, years) => ({
    disabled: parametersLoading || years.loading || years.error
}))

export default compose(
    reduxForm({}),
    connect(mapStateToProps)
)(ParametersFilters)
