import { fetchFactory } from 'isotope-client'

const API_PREFIX = '/api/me'

export const updateAccount = (values) => fetchFactory('', {
    method: 'PUT',
    body: JSON.stringify(values)
}, API_PREFIX)

export const updatePassword = (values, email) => fetchFactory(`/password`, {
    method: 'PUT',
    body: JSON.stringify(values)
}, API_PREFIX)
