import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Grid, InputAdornment } from '@material-ui/core'
import { Field, FormSection } from 'redux-form'
import { OutlinedFormInput } from '../../../components/redux-form'
import { combineValidators, validateMandatoryFields, validateNumberRange } from '../../../utils/form'
import Subtitle from '../../../components/Subtitle'
import PropTypes from 'prop-types'

const categoryFields = [
    'categoryO',
    'categoryP',
    'categoryM',
    'categoryHm',
    'categoryIle',
]

const validateMandatory = validateMandatoryFields(categoryFields)
const validateRegularRanges = validateNumberRange(categoryFields, 0, 9999)
export const validate = combineValidators([validateMandatory, validateRegularRanges])

const CompensationFactors = ({
    species,
    section,
    disabled
}) => {
    return (
        <Fragment>
            <Subtitle>
                <FormattedMessage id={`parameters.${species}.${section}.title`}/>
            </Subtitle>
            <FormSection name={section}>
                <Grid container>
                    <Grid item xs={4}>
                        <Field
                            name="categoryO"
                            label={<FormattedMessage id={`parameters.${species}.${section}.categoryO`}/>}
                            component={OutlinedFormInput}
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>
                            }}
                            min={0}
                            max={9999}
                            step={0.01}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                            name="categoryP"
                            label={<FormattedMessage id={`parameters.${species}.${section}.categoryP`}/>}
                            component={OutlinedFormInput}
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>
                            }}
                            min={0}
                            max={9999}
                            step={0.01}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                            name="categoryM"
                            label={<FormattedMessage id={`parameters.${species}.${section}.categoryM`}/>}
                            component={OutlinedFormInput}
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>
                            }}
                            min={0}
                            max={9999}
                            step={0.01}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                            name="categoryHm"
                            label={<FormattedMessage id={`parameters.${species}.${section}.categoryHm`}/>}
                            component={OutlinedFormInput}
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>
                            }}
                            min={0}
                            max={9999}
                            step={0.01}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                            name="categoryIle"
                            label={<FormattedMessage id={`parameters.${species}.${section}.categoryIle`}/>}
                            component={OutlinedFormInput}
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>
                            }}
                            min={0}
                            max={9999}
                            step={0.01}
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
            </FormSection>
        </Fragment>
    )
}

CompensationFactors.propType = {
    species: PropTypes.number.isRequired,
    section: PropTypes.string.isRequired,
    disabled: PropTypes.bool
}

export default CompensationFactors
