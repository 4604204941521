import { branch, compose, renderComponent } from 'recompose'
import { createSelector } from 'reselect'
import { connect } from 'react-redux'
import BovineParameters from './animal/BovineParameters'
import GoatParameters from './animal/GoatParameters'
import { PARAMETERS_TYPE } from '../../appConst'
import { parametersErrorSelector, parametersLoadingSelector, parametersSelector } from './selectors'
import Loader from '../../components/Loader'
import UnknownErrorFragment from '../../components/UnknownErrorFragment'
import GeneralParameters from './GeneralParameters'

const mapStateToProps = createSelector([
    parametersSelector,
    parametersLoadingSelector,
    parametersErrorSelector
], (parameters, loading, error) => ({
    parameters,
    loading,
    error
}))

export default compose(
    connect(mapStateToProps),
    branch(
        props => props.loading || !props.parameters,
        renderComponent(Loader)
    ),
    branch(
        props => props.error,
        renderComponent(UnknownErrorFragment)
    ),
    branch(
        props => props.parameters.species === PARAMETERS_TYPE.BOVINE,
        renderComponent(BovineParameters)
    ),
    branch(
        props => props.parameters.species === PARAMETERS_TYPE.GOAT,
        renderComponent(GoatParameters)
    )
)(GeneralParameters)
