import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field } from 'redux-form'
import { pure } from 'recompose'
import { FormInput } from 'isotope-client'
import { Form } from '../../components/redux-form'
import {
    checkWithConstraints,
    digits,
    lowercase,
    minLength,
    noAccent,
    specialChar,
    uppercase,
    validateMandatoryFields
} from '../../utils/form'
import PasswordConstraintsContext from '../password/PasswordConstraintsContext'

const validateMandatory = validateMandatoryFields(['oldPassword', 'password', 'confirmation'])

const validate = (values, props) => {
    const errors = validateMandatory(values)
    if (!errors.oldPassword && !errors.password) {
        if (values.oldPassword === values.password) {
            errors.password = { id: 'password.errors.newPasswordContainsOld' }
        } else {
            const {
                minLength: minLen,
                nbMinUppercases,
                nbMinLowercases,
                nbMinNumbers,
                nbMinSpecialCharacters
            } = props
            errors.password = checkWithConstraints(values.password, [
                lowercase(nbMinLowercases),
                uppercase(nbMinUppercases),
                digits(nbMinNumbers),
                specialChar(nbMinSpecialCharacters),
                noAccent,
                minLength(minLen)
            ])
        }
        if (!errors.password && !errors.confirmation && values.password !== values.confirmation) {
            errors.confirmation = { id: 'change_password.validation.confirmation' }
        }
    }
    return errors
}

const PasswordForm = ({
    form,
    onUpdatePassword
}) => (
    <PasswordConstraintsContext.Consumer>
        {(passwordConfig) => (
            <Form
                {...passwordConfig}
                form={form}
                validate={validate}
                showCancelButton={false}
                submitLabel={<FormattedMessage id="btn.update" />}
                onSubmit={onUpdatePassword}
            >
                <Field
                    name="oldPassword"
                    component={FormInput}
                    label={<FormattedMessage id="change_password.old_password"/>}
                    type="password"
                    required
                    inputProps={{ autoComplete: 'current-password' }}
                />
                <Field
                    name="password"
                    component={FormInput}
                    label={<FormattedMessage id="change_password.new_password"/>}
                    type="password"
                    required
                    inputProps={{ autoComplete: 'new-password' }}
                />
                <Field
                    name="confirmation"
                    component={FormInput}
                    label={<FormattedMessage id="change_password.confirmation"/>}
                    type="password"
                    required
                    inputProps={{ autoComplete: 'new-password' }}
                />
            </Form>
        )}
    </PasswordConstraintsContext.Consumer>
)

PasswordForm.propTypes = {
    form: PropTypes.string.isRequired,
    onUpdatePassword: PropTypes.func.isRequired
}

export default pure(PasswordForm)
