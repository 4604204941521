import React from 'react'
import { FormattedMessage } from 'react-intl'
import { pure } from 'recompose'
import { CheckCircleOutline } from '@material-ui/icons'
import MessageWithIconFragment from 'isotope-client/components/layout/MessageWithIconFragment'
import { CALCULATION_STATUS } from '../../appConst'

const CalculationTerminatedStep = () => {
    return (
        <MessageWithIconFragment
            Icon={CheckCircleOutline}
            message={<FormattedMessage id={`calculation.steps.${CALCULATION_STATUS.VALIDATED}.content`}/>}
        />
    )
}

export default pure(CalculationTerminatedStep)
