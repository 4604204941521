import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import { compose } from 'recompose'
import { Typography, Grid, withStyles } from '@material-ui/core'
import { getStyles, ButtonWithLoader } from 'isotope-client'

const styles = theme => getStyles({
    buttons: {
        marginTop: theme.spacing(4)
    }
})

const CalculationForm = ({
    classes,
    children,
    submitLabel,
    onRelaunchCalculation,
    showRelaunchButton,
    submitDisabled,
    showSubmitButton,
    relaunchDisabled,
    calculating,
    canLockParameters,
    // redux-form
    submit,
    submitting
}) => {
    return (
        <div>
            {children}
            <Grid container justify="center" spacing={1} className={classes.buttons}>
                {
                    showRelaunchButton && (
                        <Grid item>
                            <ButtonWithLoader
                                onClick={onRelaunchCalculation}
                                disabled={relaunchDisabled}
                                loading={submitting || calculating}
                            >
                                <Typography variant="body1" color="primary">
                                    <FormattedMessage id="calculation.relaunch.label" />
                                </Typography>
                            </ButtonWithLoader>
                        </Grid>
                    )
                }
                {
                    showSubmitButton && (
                        <Grid item>
                            <ButtonWithLoader
                                variant="contained"
                                color="primary"
                                onClick={submit}
                                disabled={submitDisabled || calculating || !canLockParameters}
                                loading={(!showRelaunchButton && calculating) || submitting}
                            >
                                <Typography variant="body1" color="inherit">
                                    {submitLabel}
                                </Typography>
                            </ButtonWithLoader>
                        </Grid>
                    )
                }
            </Grid>
        </div>
    )
}

CalculationForm.propTypes = {
    children: PropTypes.node,
    showSubmitButton: PropTypes.bool,
    showRelaunchButton: PropTypes.bool,
    relaunchDisabled: PropTypes.bool,
    submitDisabled: PropTypes.bool,
    calculating: PropTypes.bool,
    canLockParameters: PropTypes.bool,
    submitLabel: PropTypes.node.isRequired,
    onRelaunchCalculation: PropTypes.func.isRequired,
    // redux-form
    form: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired
}

export default compose(
    reduxForm({}),
    withStyles(styles)
)(CalculationForm)
