import { Toolbar as MuiToolbar } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { getStyles } from 'isotope-client'
import ProfileMenu from './ProfileMenu'
import Headline from './Headline'

const style = theme => getStyles({
    title: {
        flex: 1,
        marginBottom: 0
    }
})

class Toolbar extends PureComponent {
    render() {
        const {
            title,
            classes
        } = this.props
        return (
            <MuiToolbar>
                <Headline
                    noWrap
                    className={classes.title}
                >
                    {title}
                </Headline>
                <ProfileMenu />
            </MuiToolbar>
        )
    }
}

Toolbar.propTypes = {
    title: PropTypes.node
}

export default withStyles(style)(Toolbar)
