import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Grid, Box } from '@material-ui/core'
import { reduxForm, UploadFile } from '../../../../components/redux-form'
import Loader from '../../../../components/Loader'
import { FormattedMessage } from 'react-intl'
import {
    combineValidators,
    validateFileExtension,
    validateFileMaxSize
} from '../../../../utils/form'

const inputProps = {
    accept: '.xls, .xlsx'
}

const validateSize = validateFileMaxSize(['file'])
const validateExtension = validateFileExtension(['file'], inputProps.accept.split(', '))

const validate = combineValidators([validateSize, validateExtension])

const UploadRequestFileForm = ({
    file,
    downloadLink,
    //redux-form
    submitting
}) => {
    return (
        <Grid container>
            <Grid item>
                <Field
                    name="file"
                    label={<FormattedMessage id="compensation_claim.details.request_file.label"/>}
                    component={UploadFile}
                    inputProps={inputProps}
                    required
                    disabled={submitting}
                    initialValue={file}
                    downloadLink={downloadLink}
                />
            </Grid>
            {
                submitting && (
                    <Box position="relative" component={Grid} item>
                        <Loader />
                    </Box>
                )
            }
        </Grid>
    )
}

UploadRequestFileForm.propTypes = {
    form: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    file: PropTypes.object,
    downloadLink: PropTypes.string
}

export default reduxForm({
    submitWhenDirty: true,
    validate
})(UploadRequestFileForm)
