import { fetchFactory, postMultipart } from 'isotope-client'
import { SUFFIX } from './const'

// pour générer les id des appels
let idx = 1
const pendingCalls = {}

const callApi = (action, typeWithoutSuffix, next) => {
    const {
        method,
        url,
		api,
        options
    } = action
    next({
        type: `${typeWithoutSuffix}_LOADING`,
        payload: true
    })
    // TODO
    let promise
    if (method === 'POST_MULTIPART') {
        promise = postMultipart(url, options, api)
    } else {
        promise = fetchFactory(url, {
            ...options,
            credentials: 'include',
            method
        }, api)
    }
    // ajout de l'appel en attente
    const id = idx++
    pendingCalls[id] = promise
    return promise
        .then((response) => {
            next({
                type: typeWithoutSuffix,
                loading: false,
                payload: response
            })
        }, (error) => {
            if (process.env.NODE_ENV !== 'production') {
                console.error(typeWithoutSuffix, error)
            }
            next({
                type: `${typeWithoutSuffix}_ERROR`,
                loading: false,
                payload: error
            })
            // rethrow
            throw error
        })
        .finally(() => {
            delete pendingCalls[id]
            if (Object.keys(pendingCalls).length === 0) {
                next({
                    type: 'GLOBAL_LOADING',
                    payload: false
                })
            }
        })
}

/*
 * TODO :
 * - annulation d'appels concurrents (race)
 * - loading bar globale
 * - vider ou non les erreurs locales au changement de page
 * - conserver les X derniers appels en mémoire
 */
const apiMiddleware = store => next => action => {
	if (action.type.startsWith(SUFFIX)) {
		const {
			type,
			cache,
			selector
		} = action
		const typeWithoutSuffix = type.substr(SUFFIX.length)
		if (cache) {
			const value = selector(store.getState()).value
			const loading = selector(store.getState()).loading
			const date = selector(store.getState()).date
            if (!loading && (date === null || !cache.isValid(new Date(date)))) {
				return callApi(action, typeWithoutSuffix, next)
			}
            next({
                type: typeWithoutSuffix,
                // TODO améliorer pour la loading bar
                loading: false,
                payload: value
            })
			return Promise.resolve(value)
		}
		return callApi(action, typeWithoutSuffix, next)
	}
	// non supporté par le middleware
	return next(action)
}

export default apiMiddleware
