import {
    FormHelperText,
    Grid,
    withStyles
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { getStyles } from 'isotope-client'
import FormInput from './FormInput'
import getErrorStyle from './getErrorStyle'
import { hasText } from '../../../utils/stringUtils'
import DownloadLink from '../../DownloadLink'

const styles = theme => getStyles({
    textFieldRoot: {
        width: 'fit-content'
    },
    textFieldInput: {
        backgroundColor: theme.palette.common.white,
        padding: '7px 0'
    },
    /**
     * Margin entre 2 inputs si helper text
     */
    errorText: getErrorStyle(theme),
    /**
     * Margin entre champs input et helper text si helper text
     */
    marginError: {
        marginBottom: theme.spacing(-0.3)
    },
    /**
     * Margin entre 2 inputs si pas d'helper text
     */
    marginNoError: {
        marginBottom: theme.spacing(4.2)
    },
    infoText: {
        color: theme.palette.text.primary
    }
})

const displayDownloadFile = (props) => (
    (!props.value || !props.value instanceof FileList) && !!props.initialValue
)

/**
 * Méthode pour convertir FileList en Array.
 * Elle permet de fix le bug qu'en firefox prevProps.input.value
 * et this.props.input.value sont toujours le même lors que la valeur
 * est du type FileList
 * @param delegate
 * @return {function(*): *}
 */
const adaptFileEventToValue = delegate => {
    return e => {
        const files = Array.from(e.target.files)
        return delegate(files)
    }
}

const UploadFileInput = (props) => {
    const {
        classes: {
            errorText,
            infoText,
            textFieldRoot,
            marginError,
            marginNoError,
            ...classes
        },
        error,
        meta,
        initialValue,
        downloadLink,
        onChange,
        onFocus,
        onBlur,
        ...otherProps
    } = props
    const newProps = {
        error,
        meta,
        classes,
        onChange: adaptFileEventToValue(onChange),
        onFocus: adaptFileEventToValue(onFocus),
        onBlur: adaptFileEventToValue(onBlur),
        ...otherProps
    }

    const accept = props.inputProps && props.inputProps.accept
    const hasError = typeof error !== 'undefined' && (!meta || meta.touched)
    const inputRef = React.useRef()
    React.useEffect(() => {
        // Supprimer le fichier
        if (!otherProps.value) {
            inputRef.current.value = ''
        }
    }, [otherProps.value])
    return (
        <div>
            <Grid
                classes={{ root: hasError ? marginError : marginNoError }}
                container
                alignItems="center"
            >
                <Grid item>
                    <FormInput
                        {...newProps}
                        inputStyle={textFieldRoot}
                        variant="standard"
                        type="file"
                        value={undefined}
                        helperText={hasText(accept) &&
                        <FormattedMessage id="global.accepted_file_extensions"
                                          values={{extensions: accept}}/>
                        }
                        InputProps={{
                            disableUnderline: true,
                            inputRef
                        }}
                        InputLabelProps={{shrink: true}}
                        FormHelperTextProps={{
                            classes: {
                                root: infoText
                            }
                        }}
                    />
                </Grid>
                <Grid item>
                    {
                        displayDownloadFile(props) && (
                            <DownloadLink href={downloadLink}>
                                {initialValue.name}
                            </DownloadLink>
                        )
                    }
                </Grid>
            </Grid>
            <FormHelperText
                classes={{
                    root: hasError && errorText
                }}
            >
                {(!meta || meta.touched) && error}
            </FormHelperText>
        </div>
    )
}

UploadFileInput.propTypes = {
    initialValue: PropTypes.shape({
        name: PropTypes.string.isRequired,
        size: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired
    }),
    downloadLink: PropTypes.string
}

export default withStyles(styles, { name: 'uploadFileInput' })(UploadFileInput)
