export const getFileSize = (fileList) => {
    if (fileList instanceof FileList || fileList instanceof Array) {
        const fileArray = []
        for (let i = 0; i < fileList.length; i++) {
            fileArray.push(fileList[i])
        }
        return fileArray.reduce((sum, file) => sum + file.size, 0)
    }
    return NaN
}

export const isFileExist = (fileList) => {
    return fileList && fileList[0] && fileList[0] instanceof File && fileList[0].length !== 0
}

/**
 * Obtenir l'extension du fichier
 * @param file     le fichier
 * @return {string} l'extension prefixée avec "."
 */
export const getFileExtension = (file) => {
    if (file instanceof File) {
        const parts = file.name.split('.')
        if (parts.length > 0) {
            return `.${parts[parts.length - 1]}`
        }
    }
}

const PDF_CONTENT_TYPE = 'application/pdf'
const EXCEL_CONTENT_TYPES = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
]

export const isContentTypePdf = (type) => PDF_CONTENT_TYPE === type
export const isContentTypeExcel = (type) => EXCEL_CONTENT_TYPES.indexOf(type) >= 0
