import {
    updateAccount as updateAccountApi,
    updatePassword as updatePasswordApi
} from './api'
import { GET_USER_SUCCESS } from 'isotope-client'

export const updateAccount = (values) => dispatch => {
    return updateAccountApi(values)
        .then((user) => {
            dispatch({
                type: GET_USER_SUCCESS,
                user
            })
            return user
        })
}

export const updatePassword = (values, email) => dispatch => {
    return updatePasswordApi(values, email)
        .then((user) => {
            dispatch({
                type: GET_USER_SUCCESS,
                user
            })
            return user
        })
}
