import { combineReducers } from 'redux'

const createReducer = (name, initialValue = null) => {
	const loading = (state = true, action) => {
		switch (action.type) {
			case name + '_LOADING':
				return true
			case name:
			case name + '_ERROR':
				return false
			default:
				return state
		}
	}
	const error = (state = false, action) => {
		switch (action.type) {
			case name:
				return false
			case name + '_ERROR':
				return true
			default:
				return state
		}
	}
	const date = (state = null, action) => {
		switch (action.type) {
			case name:
				return new Date().getTime()
			default:
				return state
		}
	}
	const value = (state = initialValue, action) => {
		switch (action.type) {
			case name:
				return action.payload
			default:
				return state
		}
	}
	return combineReducers({
		loading,
		error,
		value,
		date
	})
}

export default createReducer
