import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'

/**
 * Enhancer du redux-form qui permet d'effectuer la soumission dès que la valeur d'un champ est changée.
 *  * Le flag "submitWhenDirty" permet d'indiquer que le redux-form est submit seulement s'il est dirty, par défaut
 * c'est à false
 */
export default ({
    submitWhenDirty = false,
    ...config
}) => (WrappedComponent) => {
    let timer
    const handleChange = (values, dispatch, props) => {
        if (timer) {
            clearTimeout(timer)
        }
        if (!submitWhenDirty || props.dirty) {
           timer = setTimeout(props.submit, 0)
        }
    }
    class AutoSubmitReduxForm extends PureComponent {
        componentWillUnmount() {
            if (timer) {
                clearTimeout(timer)
            }
        }
        render() {
            return (
                <WrappedComponent {...this.props} />
            )
        }
    }
    return reduxForm({
        ...config,
        onChange: handleChange
    })(AutoSubmitReduxForm)
}
