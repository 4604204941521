import { AppBar, Drawer } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { getStyles } from 'isotope-client'
import Toolbar from '../components/Toolbar'
import SideMenu from '../components/SideMenu'
import menuEntries from './menuEntries'
import routes, { defaultRoute } from '../routes'

const menuWidth = 250
const appBarHeight = 64
export const actionButtonsHeight = 65

const style = theme => getStyles({
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%'
    },
    appBar: {
        flex: 1,
        paddingLeft: menuWidth
    },
    contentFrame: {
        position: 'absolute',
        display: 'flex',
        overflow: 'auto',
        bottom: 0,
        left: 0,
        right: 0,
        top: appBarHeight
    },
    mainContent: {
        position: 'relative',
        flexGrow: 1,
        marginLeft: menuWidth
    },
    drawerPaper: {
        width: menuWidth,
        zIndex: 2
    },
    title: {
        marginBottom: 0
    }
})

const Page = ({
    location,
    classes,
    children,
}) => {
    const [title, setTitle] = React.useState('compensation_claim.title')

    React.useEffect(() => {
        const routeEntry = routes.find(entry => entry.path === location.pathname)
        setTitle(`${routeEntry ? routeEntry.title : defaultRoute.title}.title`)
    }, [location, location.pathname])

    return (
        <Fragment>
            <div className={classes.appFrame}>
                <AppBar
                    elevation={0}
                    className={classes.appBar}
                >
                    <Toolbar title={<FormattedMessage id={title}/>}/>
                </AppBar>
            </div>
            <div className={classes.contentFrame}>
                <Drawer
                    variant="permanent"
                    open
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <SideMenu
                        location={location}
                        menuEntries={menuEntries}
                    />
                </Drawer>
                <main className={classes.mainContent}>
                    {children}
                </main>
            </div>
        </Fragment>
    )
}

Page.propTypes = {
    classes: PropTypes.object,
    children: PropTypes.node
}


export default withStyles(style)(Page)

