import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { connect } from 'react-redux'
import Headline from '../../components/Headline'
import {
    broadcastCalculation,
    getCalculation,
    launchCalculation,
    refreshCalculation,
    setCalculationStatusToInitial,
    validateCalculation,
    watchCalculation
} from './actions'
import { calculationDoneSelector } from './selectors'
import { FormattedMessage } from 'react-intl'
import { CALCULATION_STATUS } from '../../appConst'
import CalculationActiveStep from './CalculationActiveStep'
import CalculationTerminatedStep from './CalculationTerminatedStep'
import Steppers from './Steppers'
import CalculationForm from './CalculationForm'

const getStepComponent = (status) => {
    if (CALCULATION_STATUS.VALIDATED === status) {
        return CalculationTerminatedStep
    }
    return CalculationActiveStep
}

const showRelaunchButton = (status) => {
    return CALCULATION_STATUS.TEMPORARY_CALCULATED === status || CALCULATION_STATUS.BROADCAST === status
}

const showSubmitButton = (status, year) => {
    return status !== CALCULATION_STATUS.VALIDATED || year < new Date().getFullYear()
}

class CalculationSteppers extends PureComponent {
    componentDidMount() {
        // A la montée du composant, vérifier s'il y a un calcul en cours
        if (this.props.calculation.calculating) {
            this.props.watchCalculation()
        }
    }

    handleSubmit = () => {
        const { calculation } = this.props
        switch (calculation.status) {
            case CALCULATION_STATUS.PARAMETERS_INIT:
                return  this.props.setCalculationStatusToInitial(calculation.year)
            case CALCULATION_STATUS.INITIAL:
                return this.props.launchCalculation(calculation.year)
            case CALCULATION_STATUS.TEMPORARY_CALCULATED:
                return this.props.broadcastCalculation(calculation.year)
            case CALCULATION_STATUS.BROADCAST:
                return this.props.validateCalculation(calculation.year)
            case CALCULATION_STATUS.VALIDATED:
                return this.props.refreshCalculation(calculation.year + 1)
            default:
                return Promise.resolve()
        }
    }

    isSubmitDisabled = () => {
        const {
            calculation
        } = this.props
        switch (calculation.status) {
            case CALCULATION_STATUS.INITIAL:
                return !calculation.canCalculate
            case CALCULATION_STATUS.TEMPORARY_CALCULATED:
                return !calculation.canBroadcast
            case CALCULATION_STATUS.BROADCAST:
                return !calculation.canValidate
            default:
                return false
        }
    }

    handleRelaunchCalculation = () => {
        const { calculation } = this.props
        return this.props.launchCalculation(calculation.year)
    }

    render() {
        const {
            calculation,
            calculating
        } = this.props
        return (
            <Fragment>
                <Headline>
                    <FormattedMessage id={`calculation.header_title`} values={{year: calculation.year }} />
                </Headline>
                <Steppers currentStep={calculation.status} />
                <CalculationForm
                    form="CalculationForm"
                    destroyOnUnmount={false}
                    forceUnregisterOnUnmount
                    submitLabel={<FormattedMessage id={`calculation.steps.${calculation.status}.submit`} />}
                    showRelaunchButton={showRelaunchButton(calculation.status)}
                    showSubmitButton={showSubmitButton(calculation.status, calculation.year)}
                    submitDisabled={this.isSubmitDisabled()}
                    relaunchDisabled={!calculation.canCalculate}
                    calculating={calculating}
                    canLockParameters={calculation.canLockParameters}
                    onSubmit={this.handleSubmit}
                    onRelaunchCalculation={this.handleRelaunchCalculation}
                >
                    {
                        React.createElement(getStepComponent(calculation.status), {
                            step: calculation.status
                        })
                    }
                </CalculationForm>
            </Fragment>
        )
    }
}

CalculationSteppers.propTypes = {
    calculation: PropTypes.shape({
        status: PropTypes.number.isRequired,
        year: PropTypes.number.isRequired,
        calculating: PropTypes.bool,
        canCalculate: PropTypes.bool,
        canBroadcast: PropTypes.bool,
        canValidate: PropTypes.bool,
        canLockParameters: PropTypes.bool
    }).isRequired
}

const mapStateToProps = createSelector([
    (state, props) => props.calculation,
    calculationDoneSelector
], (calculation, done) => ({
    calculation,
    calculating: !done
}))

const mappedActions = {
    watchCalculation,
    getCalculation,
    launchCalculation,
    broadcastCalculation,
    validateCalculation,
    refreshCalculation,
    setCalculationStatusToInitial
}

export default connect(
    mapStateToProps,
    mappedActions
)(CalculationSteppers)
