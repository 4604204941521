import {
    snackError as snackErrorIsotope,
    snackSuccess as snackSuccessIsotope
} from 'isotope-client'

const snackOptions = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
    }
}

export const snackSuccess = (message = 'notify.success') => snackSuccessIsotope({ id: message }, snackOptions)
export const snackError = (message = 'notify.error') => snackErrorIsotope({ id: message }, snackOptions)
