const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/**
 * Vérifier si le chiffre est positif
 * @param x
 * @param includeZero si 0 est pris pour positif
 * @return {boolean}
 */
export const isPositiveDigit = (x, includeZero = true) => {
    if (!x) {
        return true
    }
    const y = parseFloat(x)
    return !isNaN(y) && (includeZero ? y >= 0 : y > 0) && x.toString() === y.toString()
}

export const isPositiveInt = (x, includeZero = true) => {
    if (!x) {
        return true
    }
    const y = parseInt(x, 10)
    return !isNaN(y) && (includeZero ? y >= 0 : y > 0) && x.toString() === y.toString()
}

export const isNull = (x) => (
    typeof x === 'undefined'
    || x === null
    || (
        (typeof x === 'string' || Array.isArray(x) || x instanceof FileList)
        && x.length === 0
    )
)

export const isValidEmail = (email = '') => (
    regexEmail.test(email)
)

// value contient au moins nb minuscules
export const lowercase = (nb) => value => {
    if (!isNull(value) && !new RegExp(`^(.*[a-z]{${nb}}.*)$`).test(value)) {
        return {
            id: 'validation.lowercase',
            values: { nb }
        }
    }
}
// value contient au moins nb majuscules
export const uppercase = nb => value => {
    if (!isNull(value) && !new RegExp(`^(.*[A-Z]{${nb}}.*)$`).test(value)) {
        return {
            id: 'validation.uppercase',
            values: { nb }
        }
    }
}
// value contient au moins nb chiffres
export const digits = nb => value => {
    if (!isNull(value) && !new RegExp(`^(.*\\d{${nb}}.*)$`, 'i').test(value)) {
        return {
            id: 'validation.digits',
            values: { nb }
        }
    }
}
// value contient au moins nb caractèsre spécial
export const specialChar = nb => value => {
    if (!isNull(value) && !new RegExp(`^(.*[,#?!@$%^&*-]{${nb}}.*)$`, 'i').test(value)) {
        return {
            id: 'validation.special_char',
            values: { nb }
        }
    }
}
// value ne contient pas d'accents
export const noAccent = value => {
    if (!isNull(value) && !/^((?![\u00C0-\u017F]).)*$/i.test(value)) {
        return {
            id: 'validation.no_accent'
        }
    }
}
// value contient a une taille minimale de nb caractères
export const minLength = (minLength = 1) => value => {
    if (!isNull(value) && value.length < minLength) {
        return {
            id: 'validation.min_length',
            values: { nb: minLength }
        }
    }
}
