import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import CompensationClaimsDetailsPage from './details/CompensationClaimDetailsPage'
import CompensationClaimsList from './CompensationClaimsList'
import { isOperatorSelector } from '../user/selectors'
import { createSelector } from 'reselect'
import { connect } from 'react-redux'
import { findCompensationClaimByYear, findMyCompensationClaimByYear } from './actions'
import { YearContext } from '../../global/year/YearContext'

type CompensationClaimsPageProps = {
    isOperator: boolean
    findMyCompensationClaimByYear: (year: number) => void
    findCompensationClaimByYear: (year: number, userId: number) => void
}

const CompensationClaimsPage: FC<CompensationClaimsPageProps> = (props) => {
    const {
        findCompensationClaimByYear,
        findMyCompensationClaimByYear,
        isOperator
    } = props
    const { currentYearWithParameters: currentYear } = useContext(YearContext)
    const [ showDetails, setShowDetails ] = useState<boolean>(isOperator)
    const [ year ] = useState<number>(currentYear)

    useEffect(() => {
        if (isOperator) {
            findMyCompensationClaimByYear(year)
        }
    }, [ isOperator, findMyCompensationClaimByYear, year ])

    const handleClick = useCallback((row: any) => {
        findCompensationClaimByYear(year, row.user.id)
        setShowDetails(true)
    }, [setShowDetails, year, findCompensationClaimByYear])

    const handleBackButton = useCallback(() => {
        if (!isOperator) {
            setShowDetails(false)
        }
    }, [setShowDetails, isOperator])

    if (showDetails) {
        return (
            <CompensationClaimsDetailsPage
                currentYear={year}
                handleBackButton={handleBackButton}
            />
        )
    }
    return (
        <CompensationClaimsList
            currentYear={year}
            handleClick={handleClick}
        />
    )
}

const mapStateToProps = createSelector([isOperatorSelector], (isOperator) => ({ isOperator }))

const mappedActions = {
    findCompensationClaimByYear,
    findMyCompensationClaimByYear
}

export default connect(mapStateToProps, mappedActions)(CompensationClaimsPage)
