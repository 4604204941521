import { combineReducers } from 'redux'
import createReducer from '../../middlewares/api/createReducer'
import { CALCULATION_DONE, CALCULATION_ERROR, GET_CALCULATION, WATCH_CALCULATION } from './actions'

const status = createReducer(GET_CALCULATION)

const taskDone = (state = true, action) => {
    switch (action.type) {
        case WATCH_CALCULATION:
            return false
        case CALCULATION_DONE:
            return true
        default:
            return state
    }
}

const taskError = (state = false, action) => {
    switch (action.type) {
        case WATCH_CALCULATION:
        case CALCULATION_DONE:
            return false
        case CALCULATION_ERROR:
            return true
        default:
            return state
    }
}

const task = combineReducers({
    done: taskDone,
    error: taskError
})

export default combineReducers(({
    task,
    status
}))
