import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { FormattedMessage } from 'react-intl'
import { ErrorOutline } from '@material-ui/icons'
import MessageWithIconFragment  from 'isotope-client/components/layout/MessageWithIconFragment'

const UnknownErrorFragment = ({ message }) => (
    <MessageWithIconFragment
        Icon={ErrorOutline}
        message={message}
    />
)

UnknownErrorFragment.propTypes = {
    message: PropTypes.node
}

UnknownErrorFragment.defaultProps = {
    message: <FormattedMessage id="global.unknown_error" />
}

export default pure(UnknownErrorFragment)
