import { assertNotNullOrUndefined } from './utils'
import { Nullable } from '../global'

export type SheetError = {
    message: string
    line: number
}

export type SheetTabErrors = {
    name: string
    errors: SheetError[]
}

export type ImportError = {
    globalError: Nullable<string>,
    sheetErrors: SheetTabErrors[]
}

export type DetailledError = {
    sheet: string
    row: number
    message: string
}

export type IntErrorMessage = {
    id: string,
    ids: string[],
    defaultMessage: string,
    values: object
}

export type AppImportError = {
    sheetErrors: DetailledError[]
    globalError?: IntErrorMessage
}

const importErrorConverter = (errors: ImportError): AppImportError => {
    if (assertNotNullOrUndefined(errors.globalError)) {
        return {
            sheetErrors: convertSheetErrors(errors.sheetErrors),
            globalError: convertError(errors.globalError)
        }
    }
    return {
        sheetErrors: convertSheetErrors(errors.sheetErrors)
    }
}


/**
 * Convertit les erreurs reçues du serveur au format { sheet: ${nom_onglet}, row: ${num_ligne}, message: ${erreur} }
 */
const convertSheetErrors = (errors: SheetTabErrors[]): DetailledError[] => {
    const defaultErrors: DetailledError[] = []
    return errors.reduce((acc, sheet) => {
        return [
            ...acc,
            ...sheet.errors.map((line) => ({
                sheet: sheet.name,
                row: line.line,
                message: line.message
            }))
        ]
    }, defaultErrors)
}

/**
 * Convertit les messages d'erreur reçus du serveur au format Intl.
 */
// FIXME
const convertError = (error: any): IntErrorMessage => ({
    id: error.code || '__ERROR__',
    ids: error.codes,
    defaultMessage: error.defaultMessage,
    values: Object.assign({}, error.arguments, {
        rejectedValue: error.rejectedValue
    })
})

export default importErrorConverter
