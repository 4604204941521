import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Field } from 'redux-form'
import { createSelector } from 'reselect'
import { reduxForm } from '../../components/redux-form'
import { yearsSelector } from './selectors'
import YearsSelect from './YearsSelect'

const YearFilter = ({ disabled, mode }) => {
    return (
        <Field
            name="year"
            label={<FormattedMessage id="global.filters.year"/>}
            component={YearsSelect}
            disabled={disabled}
            mode={mode}
        />
    )
}

YearFilter.propTypes = {
    form: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    mode: PropTypes.bool
}

const mapStateToProps = createSelector([
    yearsSelector,
    (state, props) => props.disabled,
    (state, props) => props.submitting
], (years, disabled, submitting) => ({
    disabled: submitting || years.loading || disabled
}))

export default compose(
    reduxForm({}),
    connect(mapStateToProps)
)(YearFilter)
