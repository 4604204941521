import { getLocalUser, refresh as refreshApi, refreshToken as refreshTokenAction, storeLocalUser as storeLocalUserApi } from 'isotope-client'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'
import ForbiddenFragment from '../components/ForbiddenFragment'
import { getYears } from '../global/year/actions'
import YearContextProvider from '../global/year/YearContext'
import { connectedUserSelector } from '../modules/user/selectors'
import { tokenExpirationSecondsSelector } from '../properties/selectors'
import routes, { defaultRoute } from '../routes'
import Page from './Page'

class App extends Component {
    componentDidMount() {
        this.refresh()
        this.props.getYears()
        // rafraîchir 1s avant d'être expiré
        this.timer = setInterval(this.refresh, (this.props.expirationSeconds - 1000) * 1000)
    }
    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer)
        }
    }
    refresh = () => {
        if (this.props.user && this.props.user.token) {
            const token = getLocalUser()
            refreshApi(token)
                .then((res) => {
                if (res) {
                    this.props.refreshAction(res.token)
                    storeLocalUserApi(res)
                }
            })
        }
    }
    render() {
        const {
            user
        } = this.props
        if (user && user.authenticated) {
            return (
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={(props) => (
                            <Page {...props}>
                                <YearContextProvider>
                                    {React.createElement(defaultRoute.component)}
                                </YearContextProvider>
                            </Page>
                        )}
                    />
                    {
                        routes.map((routeEntry) => (
                            <Route
                                key={routeEntry.path}
                                path={routeEntry.path}
                                exact
                                render={(props) => {
                                    return (
                                        <Page {...props}>
                                            <YearContextProvider>
                                                {
                                                    typeof routeEntry.access === 'undefined' || (
                                                        typeof routeEntry.access === 'function'
                                                        && !!routeEntry.access(user)
                                                    ) ? (React.createElement(routeEntry.component)) : <ForbiddenFragment />
                                                }
                                            </YearContextProvider>
                                        </Page>
                                    )
                                }}
                            />
                        ))
                    }
                </Switch>
            )
        }
        return <Redirect to="/login" />
    }
}

App.propTypes = {
    children: PropTypes.node
}

const mapStateToProps = createSelector([
    connectedUserSelector,
    tokenExpirationSecondsSelector
], (user, expirationSeconds) => ({
    user,
    expirationSeconds
}))

const mappedActions = {
    refreshAction: refreshTokenAction,
    getYears
}

export default connect(
    mapStateToProps,
    mappedActions
)(App)
