import { Grid, InputAdornment } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Field } from 'redux-form'
import { PARAMETERS_TYPE } from '../../../appConst'
import Headline from '../../../components/Headline'
import { OutlinedFormInput } from '../../../components/redux-form'
import Form from '../../../components/redux-form/Form'
import Subtitle from '../../../components/Subtitle'
import { combineValidators, validateMandatoryFields, validateNumberRange } from '../../../utils/form'
import { handleSubmitAnimalParameters } from '../actions'
import CompensationFactors, { validate as validateCategory } from './CompensationFactors'

const SECTION_WEAK_DENSITY = 'weakDensity'
const SECTION_STRONG_DENSITY = 'strongDensity'

const highRangeFields = [
    'total'
]
const regularRangeFields = [
    'criteria',
    'classificationDensity'
]
const percentageFields = [
    'percentageLowTurnover',
    'percentageHighTurnover',
    'maximumCompensationAmount'
]
const allFields = [
    ...highRangeFields,
    ...regularRangeFields,
    ...percentageFields
]

const validateMandatory = validateMandatoryFields(allFields)
const validateHighRanges = validateNumberRange(highRangeFields, 0, 99999999)
const validateRegularRanges = validateNumberRange(regularRangeFields, 0, 9999)
const validatePercentages = validateNumberRange(percentageFields, 0, 100)
const _validate = combineValidators([
    validateMandatory,
    validateHighRanges,
    validateRegularRanges,
    validatePercentages
])
const validate = ({
                      [SECTION_WEAK_DENSITY]: weakDensity,
                      [SECTION_STRONG_DENSITY]: strongDensity,
                      ...values
                  }) => {
    const errors = _validate(values)

    errors[SECTION_WEAK_DENSITY] = validateCategory(weakDensity || {})
    errors[SECTION_STRONG_DENSITY] = validateCategory(strongDensity || {})

    return errors
}

const GoatParameters = ({ parameters, disabled }) => {
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = useCallback((values, dispatch) => {
        setSubmitting(true)
        return handleSubmitAnimalParameters(values)(dispatch).finally(() => {
            setSubmitting(false)
        })
    }, [setSubmitting])

    return (
        <Form
            form="goatParametersForm"
            initialValues={parameters}
            enableReinitialize
            validate={validate}
            onSubmit={handleSubmit}
            submitting={submitting}
            showCancelButton={!disabled}
        >
            <Headline>
                <FormattedMessage id={`parameters.${PARAMETERS_TYPE.GOAT}.title`} />
            </Headline>
            <Subtitle>
                <FormattedMessage id={`parameters.${PARAMETERS_TYPE.GOAT}.general.title`} />
            </Subtitle>
            <Grid container>
                <Grid item xs={4}>
                    <Field
                        name="total"
                        label={<FormattedMessage id={`parameters.${PARAMETERS_TYPE.GOAT}.general.total`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€</InputAdornment>
                        }}
                        min={0}
                        max={99999999}
                        step={0.01}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name="criteria"
                        label={<FormattedMessage id={`parameters.${PARAMETERS_TYPE.GOAT}.general.criteria`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">IA dans le lot</InputAdornment>
                        }}
                        min={0}
                        max={9999}
                        step={1}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name="classificationDensity"
                        label={<FormattedMessage id={`parameters.${PARAMETERS_TYPE.GOAT}.general.classificationDensity`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">IA/km²</InputAdornment>
                        }}
                        min={0}
                        max={9999}
                        step={0.01}
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
            <CompensationFactors
                species={PARAMETERS_TYPE.GOAT}
                section={SECTION_WEAK_DENSITY}
                disabled={disabled}
            />
            <CompensationFactors
                species={PARAMETERS_TYPE.GOAT}
                section={SECTION_STRONG_DENSITY}
                disabled={disabled}
            />
            <Subtitle>
                <FormattedMessage id={`parameters.${PARAMETERS_TYPE.GOAT}.compensationCalculation.title`} />
            </Subtitle>
            <Grid container justify="space-between">
                <Grid item xs={3}>
                    <Field
                        name="percentageLowTurnover"
                        label={<FormattedMessage
                            id={`parameters.${PARAMETERS_TYPE.GOAT}.compensationCalculation.percentageLowTurnover`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        min={0}
                        max={100}
                        step={0.01}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Field
                        name="percentageHighTurnover"
                        label={<FormattedMessage
                            id={`parameters.${PARAMETERS_TYPE.GOAT}.compensationCalculation.percentageHighTurnover`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        min={0}
                        max={100}
                        step={0.01}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        name="maximumCompensationAmount"
                        label={<FormattedMessage
                            id={`parameters.${PARAMETERS_TYPE.GOAT}.compensationCalculation.maximumCompensationAmount`} />}
                        component={OutlinedFormInput}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">% des coûts</InputAdornment>
                        }}
                        min={0}
                        max={100}
                        step={0.01}
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        </Form>
    )
}

export default GoatParameters
