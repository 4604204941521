import { combineReducers } from 'redux'
import calculationReducer from './modules/calculation/reducer'
import parametersReducer from './modules/parameters/reducer'
import compensationClaimReducer from './modules/compensation-claim/reducer'
import yearsReducer from './global/year/reducer'
import subsidyFilesReducer from './modules/subsidy-files/reducer'

export default combineReducers({
    subsidyFiles: subsidyFilesReducer,
    calculation: calculationReducer,
    parameters: parametersReducer,
    compensationClaim: compensationClaimReducer,
    years: yearsReducer
})
