export const USER_ROLE = {
    ADMIN: 'ADMIN',
    OPERATOR: 'OPERATOR'
}

export const USER_ROLE_VALUES = [
    USER_ROLE.ADMIN,
    USER_ROLE.OPERATOR
]

export const SPECIES = {
    BOVINE: 1,
    GOAT: 2
}

export const SPECIES_VALUES = [
    SPECIES.BOVINE,
    SPECIES.GOAT
]

export const PARAMETERS_TYPE = {
    GENERAL: 0,
    BOVINE: SPECIES.BOVINE,
    GOAT: SPECIES.GOAT
}

export const PARAMETERS_TYPE_VALUES = [
    PARAMETERS_TYPE.GENERAL,
    PARAMETERS_TYPE.BOVINE,
    PARAMETERS_TYPE.GOAT
]

export const CALCULATION_STATUS = {
    PARAMETERS_INIT: 0,
    INITIAL: 1,
    TEMPORARY_CALCULATED: 2,
    BROADCAST: 3,
    VALIDATED: 4,
}

export const CALCULATION_ACTIVE_STATUS_VALUES = [
    CALCULATION_STATUS.PARAMETERS_INIT,
    CALCULATION_STATUS.INITIAL,
    CALCULATION_STATUS.TEMPORARY_CALCULATED,
    CALCULATION_STATUS.BROADCAST,
    CALCULATION_STATUS.VALIDATED
]

export const CALCULATION_ACTIVE_STEPS = [
    CALCULATION_STATUS.PARAMETERS_INIT,
    CALCULATION_STATUS.INITIAL,
    CALCULATION_STATUS.TEMPORARY_CALCULATED,
    CALCULATION_STATUS.BROADCAST
]

export const COMPENSATION_CLAIM_STATUS = {
    FILE_AWAITING: 0,
    FILE_TO_VALIDATE: 1,
    FILE_VALIDATED: 2,
    INITIAL_CALCULATING: 3,
    CALCULATING: 4,
    FINISH: 5,
}

export const FILE_MAX_SIZE = 16 * 1024 * 1024

export const TEXT_FIELD_MAX_SIZE = 300

export const FILTER_MODE = {
    NO_FILTER: true,
    WITH_FILTER: false
}
