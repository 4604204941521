import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { FormattedMessage } from 'react-intl'
import { Grid, Box } from '@material-ui/core'
import LabelledText from '../../../../components/LabelledText'
import CompensationClaimsSpeciesFilter from './CompensationClaimsSpeciesFilter'
import { COMPENSATION_CLAIM_STATUS, SPECIES } from '../../../../appConst'
import NoFileFragment from '../NoFileFragment'
import { isContentTypeExcel, isContentTypePdf } from '../../../../utils/fileUtils'
import DownloadLink from '../../../../components/DownloadLink'
import Caption from '../../../../components/Caption'
import {
    compensationSummariesMapSelector,
    compensationSummariesLoadingSelector,
    compensationSummariesErrorSelector
} from '../../selectors'
import { fieldsSelector } from '../../../../utils/form'
import Loader from '../../../../components/Loader'

const initialFilters = {
    species: SPECIES.BOVINE
}

const FILTER_FORM = 'CompensationClaimsSpeciesFilter'

class CompensationClaimSummaryBlock extends PureComponent {

    handleGetSummaries = () => {
        /* do nothing */
    }
    getSummaryDownloadHref = (summary) => {
        return `/api/compensation_claims/${this.props.compensationClaim.id}/summary_file/${summary.id}/download`
    }

    render() {
        const {
            compensationClaim: {
                status
            },
            summaries,
            loading,
            error
        } = this.props
        return (
            <Grid container>
                <Grid item xs={4}>
                    <CompensationClaimsSpeciesFilter
                        form={FILTER_FORM}
                        initialValues={initialFilters}
                        onSubmit={this.handleGetSummaries}
                    />
                </Grid>
                {
                    loading ? (
                        <Box position="relative" component={Grid} item xs={2}>
                            <Loader />
                        </Box>
                    ) : (
                        error || !summaries || summaries.length === 0 ? (
                            <Grid item xs={8}>
                                <NoFileFragment
                                    message={<FormattedMessage id="compensation_claim.details.summary_status.empty"/>}
                                />
                            </Grid>
                        ) : (
                            <Fragment>
                                {
                                    summaries.map((summary) => {
                                        return (
                                            <Grid key={summary.id} item xs={4}>
                                                {
                                                    isContentTypePdf(summary.type) && (
                                                        <LabelledText
                                                            label={<FormattedMessage id="compensation_claim.details.summary_status.types.pdf"/>}
                                                            content={
                                                                <DownloadLink href={this.getSummaryDownloadHref(summary)}>
                                                                    {summary.name}
                                                                </DownloadLink>
                                                            }
                                                        />
                                                    )
                                                }
                                                {
                                                    isContentTypeExcel(summary.type) && (
                                                        <LabelledText
                                                            label={<FormattedMessage id="compensation_claim.details.summary_status.types.excel"/>}
                                                            content={
                                                                <DownloadLink href={this.getSummaryDownloadHref(summary)}>
                                                                    {summary.name}
                                                                </DownloadLink>
                                                            }
                                                        />
                                                    )
                                                }
                                            </Grid>
                                        )
                                    })
                                }
                                {
                                    (status === COMPENSATION_CLAIM_STATUS.CALCULATING
                                        || status === COMPENSATION_CLAIM_STATUS.INITIAL_CALCULATING) && (
                                        <Caption>
                                            <FormattedMessage id="compensation_claim.details.summary_status.information" />
                                        </Caption>
                                    )
                                }
                            </Fragment>
                        )
                    )
                }
            </Grid>
        )
    }

}

CompensationClaimSummaryBlock.propTypes = {
    compensationClaim: PropTypes.shape({
        id: PropTypes.string.isRequired,
        status: PropTypes.number.isRequired
    }).isRequired
}

const formValuesSelector = fieldsSelector(['species'], () => FILTER_FORM)

const makeMapStateToProps = () => {
    const summariesSelector = createSelector([
        compensationSummariesMapSelector,
        formValuesSelector
    ], (summariesMap, values) => {
        return summariesMap[values.species]
    })
    return createSelector([
        summariesSelector,
        compensationSummariesLoadingSelector,
        compensationSummariesErrorSelector
    ], (summaries, loading, error) => ({
        summaries,
        loading,
        error
    }))
}

export default connect(makeMapStateToProps,)(CompensationClaimSummaryBlock)
