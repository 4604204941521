import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { FormattedMessage } from 'react-intl'
import { Warning } from '@material-ui/icons'
import MessageWithIconFragment  from 'isotope-client/components/layout/MessageWithIconFragment'

const ForbiddenFragment = ({ message }) => (
    <MessageWithIconFragment
        Icon={Warning}
        message={message}
    />
)

ForbiddenFragment.propTypes = {
    message: PropTypes.node
}

ForbiddenFragment.defaultProps = {
    message: <FormattedMessage id="global.forbidden" />
}

export default pure(ForbiddenFragment)
