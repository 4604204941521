import { fetchFactory, postMultipart } from 'isotope-client'

export const updateAnimalParameters = (id, values) => fetchFactory(`/animal_parameters/${id}`, {
    method: 'PUT',
    body: JSON.stringify(values)
})

export const createAnimalParameters = (values) => fetchFactory('/animal_parameters', {
    method: 'POST',
    body: JSON.stringify(values)
})

export const updateGeneralParameters = (id, values) => postMultipart(`/general_parameters/${id}`, {
    body: values
})

export const createGeneralParameters = (values) => postMultipart('/general_parameters', {
    body: values
})
