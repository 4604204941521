import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { CALCULATION_STATUS, PARAMETERS_TYPE } from '../../appConst'
import PageFragment from '../../components/PageFragment'
import { YearContext } from '../../global/year/YearContext'
import { fieldsSelector } from '../../utils/form'
import { getParameters } from './actions'
import ParametersFilters from './ParametersFilters'
import ParametersForm from './ParametersForm'

const FILTERS_NAME = 'ParametersFilters'

type ParameterFilter = {
    year: number,
    species: number
}

type ParametersPageProps = {
    getParameters: (year: number, species: number) => void,
    values: ParameterFilter
}

const ParametersPage: FC<ParametersPageProps> = ({ getParameters, values }) => {
    const { calculationYears, currentYear } = useContext(YearContext)
    const [ filters, setFilters ] = useState<ParameterFilter>({
        year: currentYear,
        species: PARAMETERS_TYPE.GENERAL
    })

    const canModify = useMemo(() => {
        const calculationYear = calculationYears.find(calculationYear => calculationYear.year === values.year)
        return calculationYear && (calculationYear.year === currentYear) && calculationYear.status !== CALCULATION_STATUS.VALIDATED
    }, [calculationYears, values, currentYear])

    useEffect(() => { getParameters(filters.year, filters.species) }, [ getParameters, filters])

    const handleSubmitFilters = useCallback((values: ParameterFilter) => {
        setFilters(values)
    }, [setFilters])


    return (
        <PageFragment>
            <ParametersFilters
                form={FILTERS_NAME}
                initialValues={filters}
                onSubmit={handleSubmitFilters}
            />
            <ParametersForm disabled={!canModify} />
        </PageFragment>
    )
}

const formValuesSelector = fieldsSelector(['year'], () => FILTERS_NAME)

const mapStateToProps = createSelector([formValuesSelector], (values) => ({ values}))

const mappedActions = { getParameters }

export default connect(mapStateToProps, mappedActions)(ParametersPage)
