const calculationSelector = state => state.app.calculation

const _calculationStatusSelector = state => calculationSelector(state).status
export const calculationStatusSelector = state => _calculationStatusSelector(state).value
export const calculationStatusLoadingSelector = state => _calculationStatusSelector(state).loading
export const calculationStatusErrorSelector = state => _calculationStatusSelector(state).error

const calculationTaskSelector = state => calculationSelector(state).task
export const calculationDoneSelector = state => calculationTaskSelector(state).done
export const calculationErrorSelector = state => calculationTaskSelector(state).error
