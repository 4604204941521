import React from 'react'
import PropTypes from 'prop-types'
import { Step, StepLabel, Stepper } from '@material-ui/core'
import { pure, compose, branch, renderNothing } from 'recompose'
import { FormattedMessage } from 'react-intl'
import { CALCULATION_ACTIVE_STATUS_VALUES, CALCULATION_ACTIVE_STEPS, CALCULATION_STATUS } from '../../appConst'

const Steppers = ({
    currentStep
}) => {
    return (
            <Stepper activeStep={currentStep} alternativeLabel>
                {
                    CALCULATION_ACTIVE_STEPS.map((step) => (
                        <Step key={step}>
                            <StepLabel
                                StepIconProps={{
                                    // icon: ' ',
                                    completed: false
                                }}
                            >
                                <FormattedMessage id={`calculation.steps.${step}.title`}/>
                            </StepLabel>
                        </Step>
                    ))
                }
            </Stepper>
    )
}

Steppers.propTypes = {
    currentStep: PropTypes.oneOf(CALCULATION_ACTIVE_STATUS_VALUES).isRequired
}

export default compose(
    pure,
    branch(props => props.currentStep === CALCULATION_STATUS.VALIDATED, renderNothing)
)(Steppers)
