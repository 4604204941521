import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router'
import { LoginPage, Snackbar } from 'isotope-client'
import App from './App'
import ChangePasswordPage from '../login/password/ChangePasswordPage'

const InitPasswordPage = (props) => <ChangePasswordPage {...props} mode="init" />
const ResetPasswordPage = (props) => <ChangePasswordPage {...props} mode="reset" />

export default () => (
    <Fragment>
        <Switch>
            <Route path="/login" component={LoginPage}/>
            <Route path="/logout" component={LoginPage}/>
            <Route path="/init_password/:email" component={InitPasswordPage} />
            <Route path="/reset_password/:email" component={ResetPasswordPage} />
            <Route path="/" component={App}/>
            <Route path="*" component={App}/>
        </Switch>
        <Snackbar />
    </Fragment>
)

