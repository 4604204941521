import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { reduxForm } from '../../../../components/redux-form'
import { FormattedMessage } from 'react-intl'
import { SPECIES_VALUES } from '../../../../appConst'
import { MenuItem } from '@material-ui/core'
import { FormSelect } from 'isotope-client'

const CompensationClaimsSpeciesFilter = ({
    submitting
}) => {
    return (
        <Field
            name="species"
            label={<FormattedMessage id="global.filters.species"/>}
            component={FormSelect}
            disabled={submitting}
        >
            {SPECIES_VALUES.map(type => (
                <MenuItem key={type} value={type}>
                    <FormattedMessage id={`enums.SPECIES.${type}`}/>
                </MenuItem>
            ))}
        </Field>
    )
}

CompensationClaimsSpeciesFilter.propTypes = {
    form: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired
}


export default reduxForm({})(CompensationClaimsSpeciesFilter)
