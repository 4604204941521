import middle from '../../middlewares/api/middle'
import { buildUrlWithParams } from 'isotope-client/utils/urlUtils'

const prefix = 'COMPENSATION_CLAIMS/'

export const GET_COMPENSATION_CLAIM = `${prefix}GET_COMPENSATION_CLAIM`
export const GET_COMPENSATION_CLAIM_LOADING = `${prefix}GET_COMPENSATION_CLAIM_LOADING`
export const GET_COMPENSATION_CLAIM_ERROR = `${prefix}GET_COMPENSATION_CLAIM_ERROR`
export const UPDATE_COMPENSATION_CLAIM = `${prefix}UPDATE_COMPENSATION_CLAIM`

export const GET_COMPENSATION_SUMMARIES = `${prefix}GET_COMPENSATION_SUMMARIES`

export const getCompensationSummaries = (claimId) => middle(GET_COMPENSATION_SUMMARIES, {
    api: '/api',
    url: `/compensation_claims/${claimId}/summary_files`
})

export const findCompensationClaimByYear = (year, userId) => middle(GET_COMPENSATION_CLAIM, {
    api: '/api',
    url: buildUrlWithParams('/compensation_claims', { year, userId })
})

export const findMyCompensationClaimByYear = (year) => middle(GET_COMPENSATION_CLAIM, {
    api: '/api',
    url: `/compensation_claims/me?year=${year}`
})
