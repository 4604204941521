import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { FormattedMessage } from 'react-intl'
import DownloadLink from '../../../../components/DownloadLink'
import LabelledText from '../../../../components/LabelledText'

export const getDownloadHref = (compensationClaimId) => `/api/compensation_claims/${compensationClaimId}/request_file/download`

const DownloadRequestFileBlock = ({
    compensationClaim: {
        id,
        requestFile
    }
}) => {
    return (
        <LabelledText
            label={<FormattedMessage id="compensation_claim.details.request_file.label"/>}
            content={<DownloadLink href={getDownloadHref(id)}>{requestFile.name}</DownloadLink>}
        />
    )
}

DownloadRequestFileBlock.propTypes = {
    compensationClaim: PropTypes.shape({
        id: PropTypes.string.isRequired,
        requestFile: PropTypes.shape({
            name: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
}

export default pure(DownloadRequestFileBlock)
