import React from 'react'
import { FormattedMessage } from 'react-intl'
import { pure } from 'recompose'
import { Typography } from '@material-ui/core'
import PasswordConstraintsContext from './PasswordConstraintsContext'

const infoStyle = {
    marginTop: 20,
    marginBottom: 20
}

const PasswordInfoText = () => {
    return (
        <PasswordConstraintsContext.Consumer>
            {({
                minLength,
                nbMinUppercases,
                nbMinLowercases,
                nbMinNumbers,
                nbMinSpecialCharacters
            }) => (
                <Typography variant="body1" style={infoStyle}>
                    <FormattedMessage
                        id="change_password.text"
                        values={{
                            minLength,
                            nbMinUppercases,
                            nbMinLowercases,
                            nbMinNumbers,
                            nbMinSpecialCharacters,
                            br: <br/>
                        }}
                    >
                    </FormattedMessage>
                </Typography>
            )}
        </PasswordConstraintsContext.Consumer>
    )
}

export default pure(PasswordInfoText)
