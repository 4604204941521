import { combineReducers } from 'redux'
import {
    CREATE_PARAMETERS,
    GET_PARAMETERS,
    GET_PARAMETERS_ERROR,
    GET_PARAMETERS_LOADING,
    UPDATE_PARAMETERS
} from './actions'

const loading = (state = false, action) => {
    switch (action.type) {
        case GET_PARAMETERS_LOADING:
            return true
        case GET_PARAMETERS:
        case GET_PARAMETERS_ERROR:
            return false
        default:
            return state
    }
}
const error = (state = false, action) => {
    switch (action.type) {
        case GET_PARAMETERS:
            return false
        case GET_PARAMETERS_ERROR:
            return true
        default:
            return state
    }
}
const value = (state = null, action) => {
    switch (action.type) {
        case GET_PARAMETERS:
        case CREATE_PARAMETERS:
            return action.payload
        case UPDATE_PARAMETERS:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default combineReducers({
    loading,
    error,
    value
})
