import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { pure } from 'recompose'

const Caption = ({
    children
}) => {
    return (
        <Typography
            variant="caption"
            component="div"
        >{children}</Typography>
    )
}

Caption.propTypes = {
    children: PropTypes.node.isRequired
}

export default pure(Caption)
