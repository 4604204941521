import React from 'react'
import { getStyles } from 'isotope-client'
import { withStyles, Typography, InputLabel, FormControl } from '@material-ui/core'
import PropTypes from 'prop-types'
import { compose, pure } from 'recompose'

/**
 * Styles harmonisés avec {@code FormInput}
 */
const styles = theme => getStyles({
    label: {
        color: theme.palette.primary.main,
        transform: 'scale(1)'
    },
    contentRoot: {
        marginBottom: theme.spacing(4.2),
        paddingRight: theme.spacing(1.5),
        height: 30,
        width: 328,
        'label + &': {
            marginTop: theme.spacing(2.2)
        }
    },
    content: {
        padding: '7px 10px',
        color: theme.palette.text.primary
    }
})

const LabelledText = ({ classes, label, content }) => (
    <FormControl>
        <InputLabel
            className={classes.label}
            color="primary"
            shrink
        >
            {label}
        </InputLabel>
        <div className={classes.contentRoot}>
            <Typography
                className={classes.content}
                variant="body1"
            >
                {content}
            </Typography>
        </div>

    </FormControl>
)

LabelledText.propTypes = {
    label: PropTypes.node.isRequired,
    content: PropTypes.node
}

export default compose(
    pure,
    withStyles(styles)
)(LabelledText)
