import React from 'react'
import PropTypes from 'prop-types'
import { compose, branch, renderNothing } from 'recompose'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { createSelector } from 'reselect'
import { Button, Typography } from '@material-ui/core'
import { isOperatorSelector } from '../../../user/selectors'
import { updateCompensationClaimStatus } from '../../api'
import { COMPENSATION_CLAIM_STATUS } from '../../../../appConst'
import { UPDATE_COMPENSATION_CLAIM } from '../../actions'
import { snackError, snackSuccess } from '../../../../global/snackActions'

const isValidate = status => status === COMPENSATION_CLAIM_STATUS.FILE_TO_VALIDATE
const isInvalidate = status => status !== COMPENSATION_CLAIM_STATUS.FILE_AWAITING && status !== COMPENSATION_CLAIM_STATUS.FILE_TO_VALIDATE

const handleChangeStatus = (values, dispatch, props) => {
    const {
        compensationClaim: {
            id,
            status
        }
    } = props
    const validate = isValidate(status)
    return updateCompensationClaimStatus(id, { validate })
        .then((res) => {
            dispatch({
                type: UPDATE_COMPENSATION_CLAIM,
                payload: res
            })
            dispatch(snackSuccess(`compensation_claim.details.request_file.${validate ? 'validate' : 'invalidate'}.success`))
        }, (e) => {
            console.error(e)
            dispatch(snackError())
        })
}

const ChangeFileStatusBlock = ({
    compensationClaim: {
        status
    },
    // redux-form
    submit,
    submitting
}) => {
    if (isInvalidate(status)) {
        return (
            <Button
                onClick={submit}
                disabled={submitting}
            >
                <Typography variant="body1" color="primary">
                    <FormattedMessage id="compensation_claim.details.request_file.invalidate.action"/>
                </Typography>
            </Button>
        )
    }
    if (isValidate(status)) {
        return (
            <Button
                variant="contained"
                color="primary"
                onClick={submit}
                disabled={submitting}
            >
                <Typography variant="body1" color="inherit">
                    <FormattedMessage id="compensation_claim.details.request_file.validate.action"/>
                </Typography>
            </Button>
        )
    }
}

ChangeFileStatusBlock.propTypes = {
    compensationClaim: PropTypes.shape({
        id: PropTypes.string.isRequired,
        requestFile: PropTypes.object,
        status: PropTypes.number.isRequired
    }).isRequired
}

const mapStateToProps = createSelector([
    isOperatorSelector
], (isOperator) => ({
    isOperator
}))

export default compose(
    connect(mapStateToProps),
    branch(props =>
        props.isOperator || !props.compensationClaim.requestFile
        || props.compensationClaim.status === COMPENSATION_CLAIM_STATUS.FILE_AWAITING
        || props.compensationClaim.status === COMPENSATION_CLAIM_STATUS.FINISH,
        renderNothing
    ),
    reduxForm({
        form: 'ChangeFileStatusForm',
        onSubmit: handleChangeStatus
    })
)(ChangeFileStatusBlock)
