import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Headline from '../../../components/Headline'
import { Grid } from '@material-ui/core'
import LabelledText from '../../../components/LabelledText'
import { createSelector } from 'reselect'
import { connect } from 'react-redux'
import { branch, compose, renderComponent } from 'recompose'
import {
    compensationClaimErrorSelector,
    compensationClaimLoadingSelector,
    compensationClaimSelector
} from '../selectors'
import { getCompensationSummaries } from '../actions'
import Loader from '../../../components/Loader'
import UnknownErrorFragment from '../../../components/UnknownErrorFragment'
import { isOperatorSelector } from '../../user/selectors'
import RequestFileBlock from './file/RequestFileBlock'
import ChangeFileStatusBlock from './file/ChangeFileStatusBlock'
import Subtitle from '../../../components/Subtitle'
import { fieldsSelector } from '../../../utils/form'
import { COMPENSATION_CLAIM_FILTERS_NAME } from './CompensationClaimDetailsPage'
import CompensationClaimSummaryBlock from './summary/CompensationClaimSummaryBlock'

const CompensationClaimDetails = ({
    displayInfo,
    compensationClaim,
    getCompensationSummaries
}) => {
    React.useEffect(() => {
        getCompensationSummaries(compensationClaim.id)
    }, [getCompensationSummaries, compensationClaim.id])
    return (
        <React.Fragment>
            <Headline>
                <FormattedMessage id="compensation_claim.details.global_information.title"/>
            </Headline>
            <Grid container>
                <Grid item xs={4}>
                    <LabelledText
                        label={<FormattedMessage
                            id="compensation_claim.details.global_information.company_name"/>}
                        content={compensationClaim.user.empName}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LabelledText
                        label={<FormattedMessage id="compensation_claim.institution_code"/>}
                        content={compensationClaim.user.instCode}
                    />
                </Grid>
                <Grid item xs={4}>
                    {compensationClaim.siretSiren && (
                        <LabelledText
                            label={<FormattedMessage
                                id="compensation_claim.details.global_information.siret_siren"/>}
                            content={compensationClaim.siretSiren}
                        />
                    )}
                </Grid>
            </Grid>
            <Headline>
                <FormattedMessage id="compensation_claim.details.request_file.title"/>
            </Headline>
            <Grid container>
                <Grid item xs={8}>
                    <RequestFileBlock compensationClaim={compensationClaim}/>
                </Grid>
                <Grid item xs={4}>
                    <LabelledText
                        label={<FormattedMessage id="compensation_claim.status"/>}
                        content={<FormattedMessage
                            id={`enums.COMPENSATION_CLAIM_STATUS.${compensationClaim.status}`}/>}
                    />
                </Grid>
            </Grid>
            <Grid container justify="center">
                <Grid item>
                    <ChangeFileStatusBlock compensationClaim={compensationClaim}/>
                </Grid>
            </Grid>
            <Headline>
                <FormattedMessage id="compensation_claim.details.summary_status.title"/>
            </Headline>
            <CompensationClaimSummaryBlock compensationClaim={compensationClaim}/>
            {
                displayInfo && (
                    <Subtitle>
                        <FormattedMessage id="compensation_claim.information"/>
                    </Subtitle>
                )
            }
        </React.Fragment>
    )
}

CompensationClaimDetails.propTypes = {
    compensationClaim: PropTypes.object,
    displayInfo: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.bool
}

const filterValuesSelector = fieldsSelector(['year'], () => COMPENSATION_CLAIM_FILTERS_NAME)

const makeMapStateToProps = () => {
    const displayInfoSelector = createSelector([
        compensationClaimSelector,
        filterValuesSelector,
        isOperatorSelector
    ], (compensationClaim, filters, isOperator) => {
        return isOperator && compensationClaim && compensationClaim.year === new Date().getFullYear()
    })
    return createSelector([
        displayInfoSelector,
        compensationClaimSelector,
        compensationClaimLoadingSelector,
        compensationClaimErrorSelector
    ], (displayInfo, compensationClaim, loading, error) => ({
        displayInfo,
        compensationClaim,
        loading,
        error
    }))
}

const mappedActions = {
    getCompensationSummaries
}

export default compose(
    connect(makeMapStateToProps, mappedActions),
    branch(props => props.loading || !props.compensationClaim, renderComponent(Loader)),
    branch(
        props => props.error,
        renderComponent(
            () => <UnknownErrorFragment message={<FormattedMessage id="compensation_claim.details.error.empty"/>}/>
        )
    )
)(CompensationClaimDetails)
