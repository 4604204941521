import { all, call, put, takeLatest, delay, select, cancelled } from 'redux-saga/effects'
import { closeSnack } from 'isotope-client/components/snackbar/services/snackbarActions'
import { getCalculationTaskInfo } from './api'
import { calculationError, getCalculation, WATCH_CALCULATION } from './actions'
import { calculationDone } from './actions'
import { calculationStatusSelector } from './selectors'
import { snackError, snackSuccess } from '../../global/snackActions'
import { CALCULATION_STATUS } from '../../appConst'

const REFRESH_TIME_DELAY = 15 * 1000

/**
 * Saga qui va surveiller le statut la tâche du calcul des compensations et s'interrompre
 * une fois celle-ci terminée.
 */
function* getCalculationTaskStatus() {
    let taskStatus
    try {
        // Fermer le snack d'erreur
        yield put(closeSnack())
        taskStatus = yield call(getCalculationTaskInfo)
        while (taskStatus.running) {
            yield delay(REFRESH_TIME_DELAY)
            taskStatus = yield call(getCalculationTaskInfo)
        }
        if (taskStatus.error) {
            yield all([
                put(calculationError()),
                put(snackError('calculation.watch.error'))
            ])
        }
        const calculation = yield select(calculationStatusSelector)
        yield all([
            put(getCalculation(calculation.year)),
            put(calculationDone())
        ])

        if (!taskStatus.error && (
            CALCULATION_STATUS.TEMPORARY_CALCULATED === calculation.status
            || CALCULATION_STATUS.BROADCAST === calculation.status
        )) {
            yield put(snackSuccess('calculation.relaunch.success'))
        }
    } catch (error) {
        console.error('getCalculationTaskStatus', error)
        const calculation = yield select(calculationStatusSelector)
        yield all([
            put(getCalculation(calculation.year)),
            put(calculationDone())
        ])
    } finally {
        if (yield cancelled()) {
           /*  cette tâche s'est fait annulée */
        }
    }
}

function* watchCalculation() {
    yield takeLatest(WATCH_CALCULATION, getCalculationTaskStatus)
}

export default watchCalculation
