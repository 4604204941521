import { withStyles } from '@material-ui/core'
import React from 'react'
import { getStyles } from 'isotope-client'
import FormInput from './FormInput'
import getErrorStyle from './getErrorStyle'

const styles = theme => getStyles({
    textFieldInput: {
        backgroundColor: theme.palette.common.white,
        padding: '7px 10px'
    },
    /**
     * Margin entre 2 inputs si helper text
     */
    errorText: getErrorStyle(theme)
})

const OutlinedFormInput = (props) => {
    const {
        classes: {
            errorText,
            ...classes
        },
        error,
        meta,
        ...otherProps
    } = props

    const hasError = typeof error !== 'undefined' && (!meta || meta.touched)

    const newProps = { error, meta, classes, ...otherProps }
    return (
        <div>
            <FormInput
                {...newProps}
                variant="outlined"
                helperText={(!meta || meta.touched) && error}
                InputLabelProps={{ shrink: false }}
                FormHelperTextProps={{
                    classes: {
                        root: hasError && errorText
                    }
                }}
            />
        </div>
    )
}

export default withStyles(styles)(OutlinedFormInput)
