import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { push } from 'connected-react-router'
import { Field, SubmissionError } from 'redux-form'
import { MenuItem, Grid } from '@material-ui/core'
import { FormInput, FormSelect } from 'isotope-client'
import { addUser as addUserApi } from 'isotope-client/modules/users/services/usersApi'
import PageFragment from '../../components/PageFragment'
import { Form } from '../../components/redux-form'
import { snackSuccess, snackError } from '../../global/snackActions'
import { USER_ROLE_VALUES } from '../../appConst'
import { combineValidators, validateEmailFields, validateMandatoryFields } from '../../utils/form'
import Headline from '../../components/Headline'

const validateMandatory = validateMandatoryFields(['empName', 'instCode', 'email', 'role'])
const validateEmail = validateEmailFields(['email'])

const validate = combineValidators([validateMandatory, validateEmail])

class AddUserPage extends PureComponent {
    handleAddUser = (values) => {
        return addUserApi(values)
            .then(() => {
                this.props.snackSuccess('users.add.success')
                this.props.goToUsersList()
            }, (e) => {
                this.props.snackError()
                throw new SubmissionError(e)
            })
    }
    handleCancel = () => {
        this.props.goToUsersList()
    }
    render() {
        const { intl } = this.props
        return (
            <PageFragment>
                <Headline>
                    <FormattedMessage id="users.add.title" />
                </Headline>
                <Form
                    form="AddUserForm"
                    validate={validate}
                    onSubmit={this.handleAddUser}
                    onCancel={this.handleCancel}
                >
                   <Grid container>
                       <Grid item xs={6}>
                           <Field
                               name="empName"
                               component={FormInput}
                               label={<FormattedMessage id="user.emp_name.label" />}
                               placeholder={intl.formatMessage({ id: 'user.emp_name.placeholder' })}
                               maxLength={50}
                               required
                           />
                           <Field
                               name="email"
                               component={FormInput}
                               label={<FormattedMessage id="user.email.label" />}
                               placeholder={intl.formatMessage({ id: 'user.email.placeholder' })}
                               maxLength={100}
                               required
                           />
                       </Grid>
                       <Grid item xs={6}>
                           <Field
                               name="instCode"
                               component={FormInput}
                               label={<FormattedMessage id="user.inst_code.label" />}
                               placeholder={intl.formatMessage({ id: 'user.inst_code.placeholder' })}
                               maxLength={50}
                               required
                           />
                           <Field
                               name="role"
                               component={FormSelect}
                               label={<FormattedMessage id="user.role.label" />}
                               required
                           >
                               {
                                   USER_ROLE_VALUES.map(role => (
                                       <MenuItem
                                           key={role}
                                           value={role}
                                       >
                                           <FormattedMessage id={`enums.USER_ROLE.${role}`}/>
                                       </MenuItem>
                                   ))
                               }
                           </Field>
                       </Grid>
                   </Grid>
                </Form>
            </PageFragment>
        )
    }
}

AddUserPage.propeTypes = {
    intl: PropTypes.object.isRequired
}

const mappedActions = {
    snackError,
    snackSuccess,
    goToUsersList: () => push('/utilisateurs')
}

export default compose(
    connect(null, mappedActions),
    injectIntl
)(AddUserPage)
