import { fetchFactory } from 'isotope-client'

export const getCalculationTaskInfo = () => fetchFactory('/calculation/task')

export const launchCalculation = (year) => fetchFactory(`/calculation/launch?year=${year}`, {
    method: 'POST'
})

export const broadcastCalculation = (year) => fetchFactory(`/calculation/broadcast?year=${year}`, {
    method: 'POST'
})

export const validateCalculation = (year) => fetchFactory(`/calculation/validate?year=${year}`, {
    method: 'POST'
})

export const refreshCalculation = (year) => fetchFactory(`/calculation/refresh?newYear=${year}`, {
    method: 'POST'
})

export const allowStartCalculation = (year) => fetchFactory(`/calculation/parameterInit?year=${year}`, {
    method: 'POST'
})
