import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Field } from 'redux-form'
import { compose, pure } from 'recompose'
import { FormInput } from 'isotope-client'
import { Form } from '../../components/redux-form'
import { combineValidators, validateEmailFields, validateMandatoryFields } from '../../utils/form'

const validateMandatory = validateMandatoryFields(['empName', 'email'])
const validateEmail = validateEmailFields(['email'])
const validate = combineValidators([validateMandatory, validateEmail])

const AccountForm = ({
    intl,
    form,
    initialValues,
    onUpdateAccountInfo
}) => (
    <Form
        form={form}
        initialValues={initialValues}
        enableReinitialize
        validate={validate}
        showCancelButton={false}
        onSubmit={onUpdateAccountInfo}
    >
        <Field
            name="empName"
            component={FormInput}
            label={<FormattedMessage id="user.emp_name.label"/>}
            placeholder={intl.formatMessage({id: 'user.emp_name.placeholder'})}
            maxLength={50}
            required
        />
        <Field
            name="instCode"
            component={FormInput}
            label={<FormattedMessage id="user.inst_code.label"/>}
            placeholder={intl.formatMessage({id: 'user.inst_code.placeholder'})}
            maxLength={4}
            disabled
            required
        />
        <Field
            name="email"
            component={FormInput}
            label={<FormattedMessage id="user.email.label"/>}
            placeholder={intl.formatMessage({id: 'user.email.placeholder'})}
            maxLength={100}
            required
        />
    </Form>
)

AccountForm.propTypes = {
    form: PropTypes.string.isRequired,
    initialValues: PropTypes.object,
    onUpdateAccountInfo: PropTypes.func.isRequired
}

export default compose(
    pure,
    injectIntl
)(AccountForm)
