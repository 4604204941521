import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import Loader from '../../components/Loader'
import PageFragment from '../../components/PageFragment'
import UnknownErrorFragment from '../../components/UnknownErrorFragment'
import { yearsSelector } from '../../global/year/selectors'
import { getCalculation } from './actions'
import CalculationSteppers from './CalculationSteppers'
import { calculationStatusErrorSelector, calculationStatusLoadingSelector, calculationStatusSelector } from './selectors'

const CalculationPage = ({ getCalculation, years, calculation }) => {
    useEffect(() => {
        if (!years.loading && !years.error && years.value.length > 0) {
            getCalculation(Math.max(...years.value.map(yearStatus => yearStatus.year)))
        }
    }, [years.loading, years.error, years.value, getCalculation])

    if (years.error) {
        return (
            <PageFragment>
                <UnknownErrorFragment/>
            </PageFragment>
        )
    }
    if (calculation.loading || years.loading) {
        return (
            <PageFragment>
                <Loader/>
            </PageFragment>
        )
    }
    if (calculation.error) {
        return (
            <PageFragment>
                <UnknownErrorFragment/>
            </PageFragment>
        )
    }

    return (
        <PageFragment>
            {calculation.value && <CalculationSteppers calculation={calculation.value} />}
        </PageFragment>
    )
}

const mapStateToProps = createSelector([
    yearsSelector,
    calculationStatusSelector,
    calculationStatusLoadingSelector,
    calculationStatusErrorSelector
], (years, calculation, calculationLoading, calculationError) => ({
    years,
    calculation: {
        value: calculation,
        loading: calculationLoading,
        error: calculationError
    }
}))

const mappedActions = {
    getCalculation
}

export default connect(
    mapStateToProps,
    mappedActions
)(CalculationPage)
