import { Button, Typography, withStyles } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { getStyles } from 'isotope-client'
import { isEmpty } from '../utils/stringUtils'
import { download } from 'isotope-client/components/download/IsotopeLink'

const styles = theme => getStyles({
    button: {
        maxWidth: 350,
        padding: 0,
        textTransform: 'none'
    }
})

class DownloadLink extends PureComponent {
    handleDownload = () => {
        return download(this.props.href)
    }
    render() {
        const {
            children,
            classes,
            href
        } = this.props
        const disabled = isEmpty(href)
        return (
            <Button
                onClick={this.handleDownload}
                className={classes.button}
                endIcon={!disabled && <GetApp/>}
                disabled={disabled}
            >
                <Typography noWrap color="inherit" variant="inherit">{children}</Typography>
            </Button>
        )
    }
}

DownloadLink.propTypes = {
    children: PropTypes.node,
    href: PropTypes.string
}

export default withStyles(styles)(DownloadLink)
