import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { getStyles } from 'isotope-client'
import {
    ListItem,
    MenuItem,
    ListItemText,
    ListItemIcon,
    Divider
} from '@material-ui/core'
import * as icons from '@material-ui/icons'
import withStyles from '@material-ui/core/styles/withStyles'
import { push } from 'connected-react-router'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { createSelector } from 'reselect'
import { connectedUserSelector } from '../modules/user/selectors'
import { defaultRoute } from '../routes'
import Headline from './Headline'

const blocLogo = 64

const styles = theme => getStyles({
    logo: {
        marginLeft: 0,
        marginRight: 10,
        height: 40,
        width: 'auto'
    },
    blocLogo: {
        height: blocLogo
    },
    title: {
        marginBottom: 0
    }
})

const isSelected = (entry, location) => {
    return (entry.path === defaultRoute.path && location.pathname === '/') // route par défaut
        || (entry.path && location.pathname.startsWith(entry.path))
}

class SideMenu extends PureComponent {

    render() {
        const {
            menuEntries,
            user,
            location,
            classes,
            changeMenu
        } = this.props

        return (
            <Fragment>
                <ListItem className={classes.blocLogo}>
                    <img
                        src="/resources/img/logo.png"
                        className={classes.logo}
                        alt="Logo"
                    />
                    <Headline
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        SUIA
                    </Headline>
                </ListItem>
                <Divider/>
                {
                    menuEntries.filter(entry => typeof entry.access === 'undefined' || (typeof entry.access === 'function' && entry.access(user)))
                        .map((entry) => {
                            const selected = isSelected(entry, location)
                            return (
                                <Fragment key={entry.text}>
                                    <MenuItem
                                        onClick={() => changeMenu(entry.path)}
                                        selected={selected}
                                        style={{padding:"12px 16px"}}
                                    >
                                        <ListItemIcon>
                                            {React.createElement(icons[entry.icon], {
                                                color: selected ? 'primary' : 'inherit'
                                            })}
                                        </ListItemIcon>
                                        <ListItemText disableTypography key="label">
                                            <FormattedMessage id={`menu.${entry.text}`}/>
                                        </ListItemText>
                                    </MenuItem>
                                    {entry.borderBottom && <Divider/>}
                                </Fragment>
                            )
                        })
                }
            </Fragment>
        )
    }
}

SideMenu.propTypes = {
    /**
     * Objet du react-router
     */
    location: PropTypes.object.isRequired,
    menuEntries: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
            access: PropTypes.func,
            borderBottom: PropTypes.bool
        })
    ).isRequired
}

const mapStateToProps = createSelector([
    connectedUserSelector
], (user) => ({
    user
}))

const mapDispatchToProps = (dispatch) => ({
    changeMenu: (value) => dispatch(push(value))
})

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(SideMenu)
