import { TextField, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { getStyles } from 'isotope-client'

const styles = theme => getStyles({
    textFieldRoot: {
        padding: 0,
        paddingRight: theme.spacing(1.5),
        height: 30,
        width: 328,
        /**
         * Margin entre label et champs input
         */
        'label + &': {
            marginTop: theme.spacing(2.2)
        }
    },
    /**
     * Surcharge de style dans le cas d'un multiline
     */
    multiline: {
        height: 'auto',
        alignItems: 'unset'
    },
    fullWidth: {
        width: 'calc(100% - 20px)'
    },
    /**
     * Margin entre champs input et helper text si helper text
     */
    marginHelperText: {
        marginBottom: theme.spacing(-0.3)
    },
    /**
     * Margin entre 2 inputs si pas d'helper text
     */
    marginNoHelperText: {
        marginBottom: theme.spacing(4.2)
    },
    textFieldInput: {},
    afterColorInput: {
        color: theme.palette.text.primary
    },
    beforeColorInput: {
        color: theme.palette.text.primary
    },
    bootstrapFormLabel: {
        color: theme.palette.primary.main,
        transform: 'scale(1)'
    },
    errorLabel: {
        color: theme.palette.error.main
    }
})

const FormInput = (props) => {
    const {
        classes,
        dirty,
        error,
        meta,
        inputStyle,
        InputProps,
        InputLabelProps,
        FormHelperTextProps,
        ...otherProps
    } = props

    const classTextFieldRoot = classNames(
        classes.textFieldRoot,
        props.multiline ? classes.multiline : {},
        props.fullWidth ? classes.fullWidth : {},
        inputStyle
    )
    const classTextFieldInput = classNames(
        classes.textFieldInput,
        props.fullWidth ? classes.fullWidth : {}
    )

    const hasError = typeof error !== 'undefined' && (!meta || meta.touched)
    return (
        <TextField
            {...otherProps}
            error={hasError}
            InputProps={{
                ...InputProps,
                classes: {
                    root: [
                        classTextFieldRoot,
                        hasError || !!props.helperText ?
                            classes.marginHelperText :
                            classes.marginNoHelperText
                    ].join(' '),
                    input: [
                        classTextFieldInput,
                        dirty ?
                            classes.afterColorInput :
                            classes.beforeColorInput
                    ].join(' ')
                }
            }}
            InputLabelProps={{
                ...InputLabelProps,
                className: hasError ?
                    [classes.bootstrapFormLabel,
                        classes.errorLabel
                    ].join(' ') :
                    classes.bootstrapFormLabel
            }}
            FormHelperTextProps={{
                ...FormHelperTextProps,
                variant: 'standard'
            }}
        />
    )
}

FormInput.propTypes = {
    /**
     * Provient de l'injection de props du FormInputHOC, permet le changement de style
     */
    dirty: PropTypes.bool,
    /**
     * Si erreur, error = \true\ sinon imposé à false
     */
    error: PropTypes.node,
    /**
     * Surcharge de la root
     */
    inputStyle: PropTypes.string,
    classes: PropTypes.shape({
        textFieldInput: PropTypes.string
    })
}

export default withStyles(styles)(FormInput)
