import React, {FC, useContext} from 'react'
// @ts-ignore
import { FormSelect } from 'isotope-client'
import { MenuItem } from '@material-ui/core'
import { YearContext } from './YearContext'
import {FILTER_MODE} from "../../appConst";

type YearsSelectProps = {
    mode: typeof FILTER_MODE,
    disabled: boolean,
    label: string
}

const YearsSelect: FC<YearsSelectProps> = ({ mode, ...props}) => {
    const { calculationYears, availableYearsWithParameters } = useContext(YearContext)
    return (
        <FormSelect {...props}>
            {
                (mode? calculationYears: availableYearsWithParameters)
                    .map(calculationYear => (
                    <MenuItem key={calculationYear.year} value={calculationYear.year}>
                        {calculationYear.year}
                    </MenuItem>
                ))
            }
        </FormSelect>
    )
}

export default YearsSelect
