import { CircularProgress, withStyles } from '@material-ui/core'
import React from 'react'

const styles = {
    circularProgress: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: 'auto'
    }
}

const Loader = ({ classes }) => (
    <CircularProgress
        color="primary"
        className={classes.circularProgress}
    />
)

export default withStyles(styles)(Loader)
