import { createIsotopeTheme } from 'isotope-client'
import { common } from '@material-ui/core/colors';
import { darken } from '@material-ui/core/styles'

const defaultTheme = createIsotopeTheme()

const main = '#E6243D'
const hoverMain = darken(main, defaultTheme.palette.action.hoverOpacity)
const error = '#F44336'
const grey1 = '#4A4A4A'
const white = common.white
const black = common.black

const captionFont = {
    fontSize: 10,
    lineHeight: 1.2,
    fontWeight: 500
}

const labelFont = {
    fontSize: 12,
    lineHeight: 1.3,
    fontWeight: 400
}

const defaultTextFont = {
    fontSize: 14,
    lineHeight: 1.35,
    fontWeight: 400
}

const contentFont = {
    fontSize: 15,
    lineHeight: 1.33,
    fontWeight: 400
}

const titleFont = {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.4
}

const subtitle1Font = {
    fontSize: 14,
    lineHeight: 1.35,
    fontWeight: 500
}

const subtitle2Font = {
    fontSize: 14,
    lineHeight: 1.35,
    fontWeight: 500
}

const customPalette = {
    primary: {
        main,
        contrastText: white
    },
    text: {
        primary: grey1,
        secondary: black
    },
    error: {
        main: error
    },
    errorColor: error
}

const overrides = {
    // MUI
    MuiTypography: {
        h5: titleFont,
        body1: defaultTextFont,
        body2: contentFont,
        subtitle1: subtitle1Font,
        subtitle2: subtitle2Font,
        caption: {
            ...captionFont,
            color: grey1,
            marginBottom: defaultTheme.spacing(4)
        },
        colorError: {
            color: error
        }
    },
    MuiPaper: {
        root: {
            color: black
        }
    },
    MuiInputAdornment: {
        root: defaultTextFont
    },
    MuiInputBase: {
        input: defaultTextFont
    },
    MuiInput: {
        input: defaultTextFont,
        underline: {
            '&:before': {
                borderBottom: 0
            },
            '&:after': {
                borderBottom: `1px solid ${main}`
            },
            '&:hover:not($disabled)': {
                '&:before': {
                    borderBottom: 0
                },
                '&:not($focused):not($error):before': {
                    borderBottom: 0
                }

            }
        }
    },
    MuiInputLabel: {
        root: labelFont
    },
    MuiOutlinedInput: {
        root: {
            '&:hover $notchedOutline': {
                borderWidth: 1,
                borderColor: defaultTheme.palette.action.hover
            },
            '&$focused $notchedOutline': {
                borderColor: main,
                borderWidth: 1
            },
            '&$disabled $notchedOutline': {
                backgroundColor: defaultTheme.palette.action.hover
            },
            MuiInputAdornment: {
                root: defaultTextFont
            }
        },
        notchedOutline: {
            borderWidth: 1,
            borderColor: defaultTheme.palette.action.hover,
            transition: defaultTheme.transitions.create([
                'border-color',
                'color'
            ])
        }
    },
    MuiMenuItem : {
        root: {
            color: grey1,
            '&$selected': {
                color: main
            }
        }
    },
    MuiListItemText: {
        root: defaultTextFont
    },
    MuiTableHead: {
        root: {
            color: main,
            ...defaultTextFont
        }
    },
    MuiTableSortLabel: {
        root: {
            color: main,
            "&:hover": {
                color: hoverMain,
                "&& $icon": {
                    color: hoverMain
                }
            },
            "&$active": {
                color: main,
                "&& $icon": {
                    color: main
                }
            }
        }
    },
    // Isotope
    snackbar: {
        success: {
            backgroundColor: grey1
        },
        error: {
            backgroundColor: grey1
        }
    },
    forminput: {
        bootstrapFormLabel: labelFont,
        textFieldInput: defaultTextFont
    },
    // SUIA
    uploadFileInput: {
        infoText: contentFont
    }
}

const customTheme = {
    login: {
        background: 'url(\'/resources/img/login_background.jpg\')'
    },
    palette: customPalette,
    overrides
}

const theme = createIsotopeTheme(customTheme)

export default theme
