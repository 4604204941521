import {
    allowStartCalculation as allowStartCalculationApi,
    broadcastCalculation as broadcastCalculationApi,
    launchCalculation as launchCalculationApi,
    refreshCalculation as refreshCalculationApi,
    validateCalculation as validateCalculationApi
} from './api'
import middle from '../../middlewares/api/middle'
import { snackError } from '../../global/snackActions'
import { getYears } from '../../global/year/actions'

const prefix = 'CALCULATION/'

export const GET_CALCULATION = `${prefix}GET_CALCULATION`
export const WATCH_CALCULATION = `${prefix}WATCH_CALCULATION`
export const CALCULATION_DONE = `${prefix}CALCULATION_DONE`
export const CALCULATION_ERROR = `${prefix}CALCULATION_ERROR`

export const getCalculation = (year) => middle(GET_CALCULATION, {
    api: '/api/admin',
    url: `/calculation?year=${year}`
})

export const launchCalculation = (year) => dispatch => {
    return launchCalculationApi(year)
        .then((res) => {
            if (res) {
                dispatch(watchCalculation())
            } else {
                dispatch(snackError('calculation.launch.error'))
            }
        }, () => {
            dispatch(snackError('calculation.launch.error'))
        })
}

export const broadcastCalculation = (year) => dispatch => {
    return broadcastCalculationApi(year)
        .then((res) => {
            dispatch({
                type: GET_CALCULATION,
                payload: res
            })
            return res
        }, () => {
            dispatch(snackError())
        })
}

export const validateCalculation = (year) => dispatch => {
    return validateCalculationApi(year)
        .then((res) => {
            dispatch({
                type: GET_CALCULATION,
                payload: res
            })
            return res
        }, () => {
            dispatch(snackError())
        })
}

export const refreshCalculation = (newYear) => dispatch => {
    return refreshCalculationApi(newYear)
        .then((res) => {
            dispatch({
                type: GET_CALCULATION,
                payload: res
            })
            dispatch(getYears())
            return res
        }, () => {
            dispatch(snackError())
        })
}
export const setCalculationStatusToInitial = (year) => dispatch => {
    return allowStartCalculationApi(year)
        .then((res) => {
            dispatch({
                type: GET_CALCULATION,
                payload: res
            })
            dispatch(getYears())
            return res
        }, () => {
            dispatch(snackError())
        })
}

/**
 * Demander la surveillance de la tâche calcul.
 */
export const watchCalculation = () => ({
    type: WATCH_CALCULATION
})

/**
 * Fin de la tâche calcul.
 */
export const calculationDone = () => ({
    type: CALCULATION_DONE
})

/**
 * Echec de la tâche calcul.
 */
export const calculationError = () => ({
    type: CALCULATION_ERROR
})
