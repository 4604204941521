import { Grid } from '@material-ui/core'
import React, {FC, useCallback, useContext, useMemo, useState} from 'react'
import { FormattedMessage } from 'react-intl'
import { Field, SubmissionError } from 'redux-form'
import {CALCULATION_STATUS, PARAMETERS_TYPE} from '../../appConst'
import Headline from '../../components/Headline'
import { OutlinedFormInput, UploadFile } from '../../components/redux-form'
import Form from '../../components/redux-form/Form'
import { snackSuccess } from '../../global/snackActions'
import { combineValidators, validateFileExtension, validateFileMaxSize, validateStringFieldLength } from '../../utils/form'
import importErrorConverter, {AppImportError} from '../../utils/import-error-converter'
import { assertNotNullOrUndefined } from '../../utils/utils'
import ImportErrorModal from '../import/ImportErrorModal'
import { createGeneralParameters, updateGeneralParameters } from './actions'
import {YearContext} from "../../global/year/YearContext";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { FormSubmitHandler} from "redux-form/lib/reduxForm";
import {Dispatch} from "redux";

const csvInputProps = {
    accept: '.csv'
}

const excelInputProps = {
    accept: '.xls, .xlsx'
}

const SURFACE_COMMUNES = 'superficieCommunes'
const COMMUNE_INSEE = 'communesInsee'

const FILE_NAMES_GEO = [
    COMMUNE_INSEE,
    'zonageIchn',
    SURFACE_COMMUNES
] as const

const FILE_NAMES_AGREEMENT = [
    'racesMenaceesBovin',
    'agrementsBovin',
    'agrementsCaprin'
] as const

const ALL_PARAMETERS_FILES = [
    ...FILE_NAMES_GEO,
    ...FILE_NAMES_AGREEMENT
] as const

const DEFINITIVE_STATES_TEXT_FIELD = [
    'definitiveStatesText'
] as const

const getInputProps = (name: string) => name === SURFACE_COMMUNES ? excelInputProps : csvInputProps
const validateSize = validateFileMaxSize(ALL_PARAMETERS_FILES)
const csvFields = ALL_PARAMETERS_FILES.filter(file => file !== SURFACE_COMMUNES)
const validateCsvExtensions = validateFileExtension(csvFields, [csvInputProps.accept])
const validateExcelExtensions = validateFileExtension([SURFACE_COMMUNES], excelInputProps.accept.split(', '))
const validateDefinitiveStatesTextLength = validateStringFieldLength(DEFINITIVE_STATES_TEXT_FIELD)
const validate = combineValidators([validateSize, validateCsvExtensions, validateExcelExtensions, validateDefinitiveStatesTextLength])

type GeneralParameterProps = {
    disabled: boolean
    parameters: Parameters
}

type ParameterFileName = typeof ALL_PARAMETERS_FILES[number]
type ParametersMedia = {
    [key in ParameterFileName]: Media | undefined
}
type Parameters = {
    id: string
    year: number
    definitiveStatesText: string
} & ParametersMedia

type Media = {
    id: string
    name: string
    type: string
    size: number
    creationDate: string
    lastModificationDate: string
}
type ValuesFormData = {
    communesInsee?: File[]
    zonageIchn?: File[]
    superficieCommunes?: File[]
    racesMenaceesBovin?: File[]
    agrementsBovin?: File[]
    agrementsCaprin?: File[]
    texteEtatDefinitif?: string
}
type SubmitType = {
    values: ValuesFormData
    dispatch: Dispatch<any>
    props: any
}

const GeneralParameters: FC<GeneralParameterProps> = ({ disabled, parameters }) => {
    const [error, setError] = useState<AppImportError | undefined>(undefined)
    const [manualSubmitting, setManualSubmitting] = useState(false)
    const close = useCallback(() => setError(undefined), [setError])
    const open = useMemo(() => assertNotNullOrUndefined(error), [error])
    const {calculationYears} = useContext(YearContext)
    const [submitCallback, setSubmitCallback] = useState<SubmitType | undefined> (undefined)
    const submit = useCallback((values: ValuesFormData, dispatch: Dispatch<any>, props: any) => {
        const promise = !!parameters.id ?
            updateGeneralParameters(parameters.id, values) :
            createGeneralParameters({year: parameters.year, ...values})
        setManualSubmitting(true)
        return promise(dispatch).then(() => {
            dispatch(snackSuccess('parameters.success'))
            props.reset()
        }).catch((e: any) => {
            if (e.bodyError.sheetErrors) {
                setError(importErrorConverter(e.bodyError))
            } else {
                setError({
                    sheetErrors: [],
                    globalError: {
                        id: '__ERROR__',
                        ids: e.codes,
                        defaultMessage: e.bodyError.message,
                        values: Object.assign({}, e.arguments, {
                            rejectedValue: e.rejectedValue
                        })
                    }
                })
            }
            throw new SubmissionError(e)
        }).finally(() => { setManualSubmitting(false) })
    }, [parameters])
    const displayPopInToDeleteOtherFields: boolean = useMemo(() => {
        for(let file of ALL_PARAMETERS_FILES.filter(file => file !== COMMUNE_INSEE)){
            if (parameters[file] !== null){
                return true
            }
        }
        return false
    }, [parameters])
    const handleSubmit: FormSubmitHandler<ValuesFormData> =
        useCallback((values, dispatch, props) => {
            if(values.communesInsee !== undefined && displayPopInToDeleteOtherFields){
                setSubmitCallback({ values, dispatch, props})
            }else {
                return submit(values, dispatch, props)
            }
            }, [setSubmitCallback, submit, displayPopInToDeleteOtherFields])

    const downloadFileLinks: Partial<Record<ParameterFileName, string>> = useMemo(() => {
        const fileLink: Partial<Record<ParameterFileName, string>> = {}
        ALL_PARAMETERS_FILES.forEach(fileType => {
            const parameter = parameters[fileType]
            if (parameter) {
                fileLink[fileType] = `/api/admin/general_parameters/files/${parameter.id}`
            }
        })
        return fileLink
    }, [parameters])

    const isCalculationInitial = useMemo(() => {
        return calculationYears.find(calcul => calcul.year === parameters.year)?.status !== CALCULATION_STATUS.PARAMETERS_INIT
    }, [calculationYears, parameters.year])
    return (
        <Form
            form="GeneralParametersForm"
            enableReinitialize
            validate={validate}
            onSubmit={handleSubmit}
            manualSubmitting={manualSubmitting}
            showCancelButton={!disabled}
            initialValues={{definitiveStatesText: parameters.definitiveStatesText}}
        >
            <>
                { error &&
                    <ImportErrorModal open={open} close={close} error={error}/>
                }
                <Headline>
                    <FormattedMessage id={`parameters.${PARAMETERS_TYPE.GENERAL}.title_geo`} />
                </Headline>
                <Grid container>
                    {
                        FILE_NAMES_GEO.map((file) => (
                            <Grid key={file} item xs={6}>
                                <Field
                                    name={file}
                                    label={<FormattedMessage id={`parameters.${PARAMETERS_TYPE.GENERAL}.${file}`} />}
                                    component={UploadFile}
                                    inputProps={getInputProps(file)}
                                    initialValue={parameters[file]}
                                    downloadLink={downloadFileLinks[file]}
                                    required
                                    disabled={disabled || isCalculationInitial}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <Headline>
                    <FormattedMessage id={`parameters.${PARAMETERS_TYPE.GENERAL}.title_agreement`} />
                </Headline>
                <Grid container>
                    {
                        FILE_NAMES_AGREEMENT.map((file) => (
                            <Grid key={file} item xs={6}>
                                <Field
                                    name={file}
                                    label={<FormattedMessage id={`parameters.${PARAMETERS_TYPE.GENERAL}.${file}`} />}
                                    component={UploadFile}
                                    inputProps={getInputProps(file)}
                                    initialValue={parameters[file]}
                                    downloadLink={downloadFileLinks[file]}
                                    required
                                    disabled={disabled}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <Headline>
                    <FormattedMessage id={`parameters.${PARAMETERS_TYPE.GENERAL}.title_recap`} />
                </Headline>
                <Grid container>
                    <Grid key={"definitiveStatesText"} item style={{width: "100%"}}>
                        <Field
                            name={"definitiveStatesText"}
                            label={<FormattedMessage id={`parameters.${PARAMETERS_TYPE.GENERAL}.texteEtatDefinitif`} />}
                            component={OutlinedFormInput}
                            type={"text"}
                            fullWidth
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
                <ConfirmationDialog
                    open={!!submitCallback}
                    title={<FormattedMessage
                        id={`parameters.import.communesInsee.dialog.title`}
                    />}
                    confirmLabel={<FormattedMessage
                        id={`parameters.import.communesInsee.dialog.confirmBtn`}/>}
                    cancelAction={() => setSubmitCallback(undefined)}
                    confirmAction={() => {
                        if(submitCallback){
                            submit(submitCallback.values, submitCallback.dispatch, submitCallback.props).finally(() => {
                            })
                            setSubmitCallback(undefined)
                        }
                    }}
                >
                    <FormattedMessage
                        id={`parameters.import.communesInsee.dialog.body`}
                    />
                </ConfirmationDialog>
            </>
        </Form>
    )
}

export default GeneralParameters