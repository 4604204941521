import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { push } from 'connected-react-router'
import { IconButton } from '@material-ui/core'
import { Delete, Done } from '@material-ui/icons'
import { SpringDataTable, dataTableActions } from 'isotope-client'
import {
    disableUser as disableUserApi,
    restoreUser as restoreUserApi
} from 'isotope-client/modules/users/services/usersApi'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import PageFragment from '../../components/PageFragment'
import ConfirmationDialog from '../../components/ConfirmationDialog'
import { snackSuccess, snackError } from '../../global/snackActions'
import { connectedUserSelector } from '../user/selectors'
import AddButton from '../../components/AddButton'

const USERS_TABLE_NAME = 'UsersList'

class UsersPage extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            headers: this.getHeaders(),
            openChangeStatusDialog: false,
            selectedUser: null
        }
    }

    makeOpenChangeStatusDialog = (user) => () => {
        this.setState({
            selectedUser: user,
            openChangeStatusDialog: true
        })
    }

    closeChangeStatusDialog = () => {
        this.setState({
            openChangeStatusDialog: false
        })
    }

    getHeaders = () => {
        return [{
            key: 'empName',
            name: <FormattedMessage id="users.table.col.emp_name.label"/>,
            sortable: true
        }, {
            key: 'email',
            name: <FormattedMessage id="users.table.col.email.label"/>,
            sortable: true
        }, {
            key: 'disabled',
            name: <FormattedMessage id="users.status.header"/>,
            sortable: true,
            render: (user) => <FormattedMessage id={`users.status.${user.disabled ? 'inactive' : 'active'}`}/>
        }, {
            key: 'role',
            name: <FormattedMessage id="users.table.col.rights.label"/>,
            sortable: true,
            render: (user) => <FormattedMessage id={`enums.USER_ROLE.${user.role}`}/>
        }, {
            key: 'action',
            render: (user) => (
                this.props.connectedUser.id !== user.id &&
                <IconButton
                    style={{padding: 0}}
                    onClick={this.makeOpenChangeStatusDialog(user)}
                >
                    {user.disabled ? <Done/> : <Delete/>}
                </IconButton>
            )
        }]
    }

    handleChangeUserStatus = () => {
        const promiseFunc = this.state.selectedUser.disabled ? restoreUserApi : disableUserApi
        return promiseFunc((this.state.selectedUser.id))
            .then(() => {
                this.closeChangeStatusDialog()
                this.props.refreshUsersTable()
                this.props.snackSuccess(`users.${this.state.selectedUser.disabled ? 'restore' : 'disable'}.success`)
            }, () => {
                this.props.snackError()
            })
    }

    render() {
        const {
            headers,
            selectedUser,
            openChangeStatusDialog
        } = this.state
        return (
            <PageFragment>
                <SpringDataTable
                    apiUrl="/users/search"
                    nom={USERS_TABLE_NAME}
                    headers={headers}
                    pageSizes={[10, 25, 50, 100]}
                />
                {
                    openChangeStatusDialog && selectedUser && (
                        <ConfirmationDialog
                            open
                            title={<FormattedMessage
                                id={`users.${selectedUser.disabled ? 'restore' : 'disable'}.dialog.title`}/>}
                            cancelAction={this.closeChangeStatusDialog}
                            confirmAction={this.handleChangeUserStatus}
                            confirmLabel={<FormattedMessage
                                id={`btn.${selectedUser.disabled ? 'restore' : 'disable'}`}/>}
                        >
                            <FormattedMessage
                                id={`users.${selectedUser.disabled ? 'restore' : 'disable'}.dialog.body`}/>
                        </ConfirmationDialog>
                    )
                }
                <AddButton
                    onClick={this.props.goToAddUserPage}
                />
            </PageFragment>
        )
    }
}

const mapStateToProps = createSelector([
    connectedUserSelector
], (user) => ({
    connectedUser: user
}))

const mappedActions = {
    snackError,
    snackSuccess,
    refreshUsersTable: () => dataTableActions.refresh(USERS_TABLE_NAME),
    goToAddUserPage: () => push('/utilisateurs/ajout')
}

export default connect(
    mapStateToProps,
    mappedActions
)(UsersPage)
