import { LoadedValue, Loading, LoadingError, LoadingValueWithError, Mapper } from '../global'

export const LOADING: Loading = {
	loading: true,
	error: false
}

export const LOADING_ERROR: LoadingError = {
	error: true,
	loading: false
}

export const createLoadedValue = <T>(value: T): LoadedValue<T> => ({
	loading: false,
	error: false,
	value
})

export const mapLoadingValue = <T, U>(loadingValue: LoadingValueWithError<T>, mapper: Mapper<T, U>): LoadingValueWithError<U> => {
	if (loadingValue.loading) {
		return LOADING
	}
	if (loadingValue.error) {
		return LOADING_ERROR
	}
	return createLoadedValue(mapper(loadingValue.value))
}
