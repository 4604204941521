import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Typography
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { pure } from 'recompose'
import { FormattedMessage } from 'react-intl'

const ConfirmationDialog = ({
    cancelAction,
    confirmAction,
    title,
    cancelLabel,
    confirmLabel,
    disableContentTypography,
    children,
    ...otherProps
}) => {
    const [loading, setLoading] = React.useState(false)
    const isMounted = React.useRef(false)

    React.useEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
        }
    }, [])

    const handleConfirm = () => {
        setLoading(true)
        Promise.resolve(confirmAction())
            .finally(() => {
                if (isMounted.current) {
                    setLoading(false)
                }
            })
    }

    return (
        <Dialog
            disableEscapeKeyDown
            {...otherProps}
        >
            <DialogTitle disableTypography>
                <Typography variant="h5">{title}</Typography>
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText color="textPrimary">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{padding: "16px 24px"}}>
                <Button
                    onClick={cancelAction}
                    color="primary"
                    variant="outlined"
                    disabled={loading}
                >
                    {cancelLabel || <FormattedMessage id="btn.cancel"/>}
                </Button>
                <Button
                    onClick={handleConfirm}
                    color="primary"
                    variant="contained"
                    disabled={loading}
                >
                    {confirmLabel || <FormattedMessage id="btn.confirm"/>}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
ConfirmationDialog.propTypes = {
	confirmAction: PropTypes.func.isRequired,
	cancelAction: PropTypes.func.isRequired,
	title: PropTypes.node.isRequired,
    confirmLabel: PropTypes.node,
    cancelLabel: PropTypes.node
}

export default pure(ConfirmationDialog)
