import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { Typography } from '@material-ui/core'
import Headline from '../../components/Headline'
import { FormattedMessage } from 'react-intl'
import { CALCULATION_ACTIVE_STEPS } from '../../appConst'

const CalculationActiveStep = ({
    step
}) => {
    return (
        <Fragment>
            <Headline>
                <FormattedMessage id={`calculation.steps.${step}.title`} />
            </Headline>
            <Typography variant="body1">
                <FormattedMessage
                    id={`calculation.steps.${step}.content`}
                    values={{ br: <br/> }}
                />
            </Typography>
        </Fragment>
    )
}

CalculationActiveStep.propTypes = {
    step: PropTypes.oneOf(CALCULATION_ACTIVE_STEPS).isRequired
}

export default pure(CalculationActiveStep)
