import flatten from 'flat'

export default flatten({
    app: {
        title: 'SUIA'
    },
    menu: {
        compensation_claim: 'Suivi de dossier',
        calculation: 'Calcul des compensations',
        subsidy_files: 'Données',
        users: 'Utilisateurs',
        parameters: 'Paramètrage'
    },
    compensation_claim: {
        title: 'Suivi de dossier',
        emp_name: 'Nom de l\'EMP',
        institution_code: 'Code organime',
        status: 'Statut du dossier',
        list: {
            title: 'Retrouvez ici l\'ensemble des dossiers des EMP'
        },
        details: {
            title: 'Retrouvez ici l\'historique de vos dossiers de demande de compensation',
            back_button: 'Revenir à la liste des EMP',
            global_information: {
                title: 'Informations générales',
                company_name: 'Nom de l\'entreprise',
                siret_siren: 'SIRET / SIREN',
            },
            request_file: {
                title: 'Dossier de demande de compensation',
                label: 'Fichier renseigné',
                empty: 'Aucun fichier n\'a été envoyé pour le moment',
                upload: {
                    success: 'Le fichier a été déposé avec succès',
                    error: {
                        dialog: {
                            title: 'Erreur lors de l\'import du fichier',
                            body: {
                                information: 'Votre fichier n’a pas pu être importé correctement car il comporte les erreurs suivantes :',
                                details: 'Onglet "{sheet_name}", ligne {n_row} : {message}',
                                details_no_sheet: 'ligne {n_row} : {message}'
                            },
                            action: 'Import un nouveau fichier'
                        }
                    }
                },
                validate: {
                    action: 'Valider le fichier',
                    success: 'Le fichier a été validé avec succès'
                },
                invalidate: {
                    action: 'Invalider le fichier',
                    success: 'Le fichier a été invalidé avec succès'
                }
            },
            summary_status: {
                title: 'Etat récapitulatif',
                empty: 'L\'état récapitulatif n\'est pas encore disponible pour le moment',
                types: {
                    pdf: 'Etat récapitulatif .pdf',
                    excel: 'Etat récapitulatif .xlsx'
                },
                information: 'Cet état récapitulatif n\'est pas définitif'
            },
            error: {
                empty: 'Aucune demande de compensation n\'a été trouvée pour cette année'
            }
        },
        information: 'Pour toutes demandes concernant votre dossier de compensation pour l\'année en cours, veuillez contacter directement Eliance à l\'adresse suia@eliance.fr'
    },
    calculation: {
        title: 'Calcul des compensations',
        header_title: 'Calcul pour l\'année {year}',
        steps: {
            0: {
                title: '1. Étape de paramètrage',
                content: ' Vous pouvez dès à présent importer les fichiers de paramétrage dans l\'onglet dédié. Tant que ce paramétrage n\'est pas réalisé, les opérateurs ne peuvent pas importer leurs données.{br}' +
                    '{br}' +
                    'Après avoir validé cette étape, vous ne pourrez plus modifier les paramètres géographiques pour l\'année.',
                submit: 'Valider le paramètrage'
            },
            1: {
                title: '2. Calcul provisoire des compensations',
                content: ' Vous pouvez dès à présent lancer le calcul de compensations : les résultats sont provisoires.{br}' +
                    '{br}' +
                    'Pour vérification des résultats, vous accédez dans l’onglet Données aux Données Provisoires, elles ne sont consultables que par Eliance. Aucun état sur le compte des opérateurs à ce stade.',
                submit: 'Lancer le calcul provisoire des compensations'
            },
            2: {
                title: '3. Diffusion des états provisoires aux EMP',
                content: ' Cette deuxième étape se déroule toujours avant la présentation des propositions de compensations à la CTI RZ de France AgriMer.{br}' +
                    '{br}' +
                    'Après vérification des résultats du calcul provisoire (onglets Données et Suivi de Dossier »), vous pouvez :{br}' +
                    '- Modifier les dossiers des opérateurs et/ou les paramètres et RELANCER LE CALCUL ;{br}' +
                    '- Après avoir consulté les données provisoires pour vérifications, vous pouvez VALIDER{br}' +
                    '{br}' +
                    'La validation permet de mettre à disposition dans chaque dossier des opérateurs les états provisoires pour vérification par les opérateurs agréés',
                submit: 'Diffuser les états provisoires aux EMP'
            },
            3: {
                title: '4. Validation définitive et diffusion des états définitifs',
                content: 'À tout moment, vous pouvez relancer un calcul suite à une modification de fichier ou de paramètres. Le lancement d’un calcul génère automatiquement de nouvelles données que vous pouvez retrouver dans l’onglet Données. Le lancement d’un calcul génère également de nouveaux états provisoires diffusés automatiquement aux EMP{br}' +
                    '{br}' +
                    'Après diffusion des chiffres à FAM, au Ministère et le calcul définitif réalisé, validez le calcul définitivement. Les documents finaux sont alors automatiquement générés :{br}' +
                    '- états définitifs et récapitulatifs de tous les opérateurs agréés pour envoi à France Agri Mer,{br}' +
                    '- états définitifs pour chaque opérateur agréé (distribué à titre d’information)',
                submit: 'Valider définitivement et diffuser des états définitifs'
            },
            4: {
                content: ' La période de calcul des compensations est terminée pour cette année',
                submit: 'Démarrer une nouvelle campagne'
            }
        },
        relaunch: {
            label: 'Nouveau calcul',
            success: 'Le calcul a été réalisé avec succès'
        },
        launch: {
            error: 'Lancement du calcul est en échec'
        },
        watch: {
            error: 'Le calcul a échoué'
        }
    },
    subsidy_files: {
        title: 'Données',
        subtitle: ' Retrouvez ici l’historique des données importantes au calcul des subventions',
        eliance: {
            title: 'Données Eliance'
        },
        fam_minisry: {
            title: 'Données FranceAgriMer et Ministère'
        },
        densitySectors: 'Liste des secteurs (densité)',
        ichnSectors: 'Liste des secteurs (ICHN)',
        consentProxyBovine: 'Agrément ou délégation - Bovin',
        consentProxyGoat: 'Agrément ou délégation - Caprin',
        compensationComparison: 'Comparaison des années N et N-1',
        axeRacial: 'Chiffre de l\'axe racial',
        summariesBovine: 'Liste des états récapitulatifs - Bovin',
        summariesGoat: 'Liste des états récapitulatifs - Caprin',
        arrondissements: 'Liste des arrondissements',
        keyNumbersCTI: 'Chiffres clés pour la CTI de FranceAgriMer',
        errors: {
            empty: 'Aucun fichier de données n\'a été trouvé pour cette année'
        }
    },
    users: {
        title: 'Utilisateurs',
        table: {
            col: {
                emp_name: {
                    label: 'Nom de l\'EMP'
                },
                email: {
                    label: 'Email'
                },
                rights: {
                    label: 'Droit'
                }
            }
        },
        disable: {
            dialog: {
                title: 'Désactivation d\'un compte',
                body: 'Etes-vous vous sûr de vouloir désactiver ce compte ?'
            },
            success: 'L\'utilisateur a été désactivé avec succès'
        },
        restore: {
            dialog: {
                title: 'Réactivation d\'un compte',
                body: 'Etes-vous vous sûr de vouloir réactiver ce compte ?'
            },
            success: 'L\'utilisateur a été reactivé avec succès'
        },
        add: {
            title: 'Ajouter un nouvel utilisateur',
            success: 'Le compte a été créé avec succès'
        }
    },
    user: {
        emp_name: {
            label: 'Nom de l\'EMP',
            placeholder: 'Nom de l\'EMP'
        },
        inst_code: {
            label: 'Code organisme',
            placeholder: 'Code organisme',
            doesNotExist: 'Ce code d\'organisme n\'existe pas'
        },
        email: {
            label: 'Email',
            placeholder: 'Email'
        },
        role: {
            label: 'Droits'
        }
    },
    my_account: {
        title: 'Mon compte'
    },
    login: {
        change_password: {
            password: {
                label: 'Mot de passe'
            },
            confirmation: {
                label: 'Confirmez le mot de passe'
            },
            validation: {
                token: 'La demande est invalide pour ce jeton et cette adresse email'
            }
        },
        init_password: {
            title: 'Initialiser votre mot de passe',
            action: 'Définir votre mot de passe',
            success: 'Votre mot de passe a été initialisé avec succès'
        },
        reset_password: {
            title: 'Réinitialiser votre mot de passe',
            action: 'Modifier votre mot de passe',
            success: 'La modification du mot de passe a été effectuée avec succès'
        }
    },
    change_password: {
        text: 'Votre mot de passe doit comporter au moins {minLength, number} caractères et doit contenir les éléments suivants :' +
            '{br}' +
            '- Au moins {nbMinUppercases, plural, one {une majuscule} other {{nbMinUppercases} majuscules}}' +
            '{br}' +
            '- Au moins {nbMinLowercases, plural, one {une minuscule} other {{nbMinLowercases} minuscules}} (les accents ne sont pas autorisés)' +
            '{br}' +
            '- Au moins {nbMinNumbers, plural, one {un chiffre} other {{nbMinNumbers} chiffres}}' +
            '{br}' +
            '- Au moins {nbMinSpecialCharacters, plural, one {un caractère spécial} other {{nbMinSpecialCharacters} caractères spéciaux}} parmi #, ?, !, @, $, ^, &, *, -',
        old_password: 'Mot de passe actuel',
        new_password: 'Nouveau mot de passe',
        confirmation: 'Confirmation du nouveau mot de passe',
        validation: {
            confirmation: 'La confirmation de votre mot de passe est incorrecte'
        }
    },
    parameters: {
        title: 'Paramètres',
        0: {
            title_geo: 'Paramètres géographiques',
            title_agreement: 'Paramètres agréments',
            title_recap: 'Paramètres des états récapitulatifs',
            zonageIchn: 'Zonage ICHN',
            communesInsee: 'Liste des communes INSEE',
            superficieCommunes: 'Superficie des communes',
            racesMenaceesBovin: 'Liste des races menacées bovins',
            agrementsBovin: 'Agréments - Bovin',
            agrementsCaprin: 'Agréments - Caprin',
            texteEtatDefinitif: 'Texte des états définitifs',
            error: {
                no_file: 'Vous devez déposer au moins un fichier'
            }
        },
        1: {
            title: 'Paramètres économiques et seuils',
            general: {
                title: 'Général',
                total: 'Enveloppe globale bovins',
                percentageAxisTerritorial: 'Pourcentage pour l\'axe territorial',
                percentageAxisRacial: 'Pourcentage pour l\'axe racial',
                criteriaEligibilityDensity: 'Critère d\'éligibilité densité',
                criteriaEligibilityIchn: 'Critère d\'éligibilité ICHN'
            },
            certificateCompensation: {
                title: 'Compensation à l\'acte',
                categoryO: 'Facteur pour catégorie O',
                categoryP: 'Facteur pour catégorie P',
                categoryM: 'Facteur pour catégorie M',
                categoryHm: 'Facteur pour catégorie HM',
                categoryIle: 'Facteur pour catégorie ILE'
            },
            distributionCompensation: {
                title: 'Compensation à la distribution',
                categoryO: 'Facteur pour catégorie O',
                categoryP: 'Facteur pour catégorie P',
                categoryM: 'Facteur pour catégorie M',
                categoryHm: 'Facteur pour catégorie HM',
                categoryIle: 'Facteur pour catégorie ILE'
            },
            compensationCalculation: {
                title: 'Calcul de la compensation',
                pcMaxTurnoverCompensation: '% du CA max de compensation autorisé',
                compensationIaDistributed: 'Compensation maximale par IA distribuée',
                compensationIaImplemented: 'Compensation maximale par IA mises en place',
                nbKmMin: 'Nombre de kilomètres minimal pour compensation',
                nbKmLimitEtp: 'Nombre limite de kilomètres par ETP',
                sectorLimitTechnician: 'Nombre limite de techniciens par secteur',
                compensationRaceLocal: 'Compensation axe racial pour une race locale',
                compensationRaceSmallSize: 'Compensation axe racial pour une race petit effectif',
                compensationThreeYears: 'Seuil de compensation sur 3 ans',
                groupMinSize: 'Taille min du groupe',
                ratioFromOverallBudget: 'Ratio des compensations totales sur l’enveloppe globale '
            }
        },
        2: {
            title: 'Paramètres économiques et seuils',
            general: {
                title: 'Général',
                total: 'Enveloppe global caprins',
                criteria: 'Critère du lot conforme au SUIA',
                classificationDensity: 'Classification densité',
            },
            weakDensity: {
                title: 'Compensation par lot pour une densité faible',
                categoryO: 'Catégorie O',
                categoryP: 'Catégorie P',
                categoryM: 'Catégorie M',
                categoryHm: 'Catégorie HM',
                categoryIle: 'Catégorie ILE'
            },
            strongDensity: {
                title: 'Compensation par lot pour une densité forte',
                categoryO: 'Catégorie O',
                categoryP: 'Catégorie P',
                categoryM: 'Catégorie M',
                categoryHm: 'Catégorie HM',
                categoryIle: 'Catégorie ILE'
            },
            compensationCalculation: {
                title: 'Calcul de la compensation',
                percentageLowTurnover: '% du CA max de compensation autorisé pour CA < 100 000€',
                percentageHighTurnover: '% du CA max de compensation autorisé pour CA > 100 000€',
                maximumCompensationAmount: 'Montant de la compensation maximal',
            }
        },
        success: 'Le paramétrage a bien été mis à jour',
        import: {
            communesInsee: {
                dialog: {
                    title: 'Avertissement',
                    body: 'Vous êtes sur le point de remplacer le fichier communes INSEE. Cette action supprime également les autres fichiers déjà importés. Souhaitez-vous continuer?',
                    confirmBtn: 'Confirmer'
                }
            }
        }
    },
    btn: {
        restore: 'Réactiver',
        disable: 'Désactiver',
        update: 'Mettre à jour',
        close: 'Fermer'
    },
    global: {
        forbidden: 'Accès interdit',
        not_found: 'Ressource non trouvée',
        unknown_error: 'Une erreur est survenue',
        accepted_file_extensions: 'Extensions disponibles : {extensions}',
        filters: {
            year: 'Année',
            species: 'Espèce'
        }
    },
    enums: {
        USER_ROLE: {
            ADMIN: 'Administrateur',
            OPERATOR: 'Opérateur'
        },
        PARAMETERS_TYPE: {
            0: 'Général',
            1: 'Bovins',
            2: 'Caprins'
        },
        SPECIES: {
            1: 'Bovins',
            2: 'Caprins'
        },
        COMPENSATION_CLAIM_STATUS: {
            0: 'En attente de fichier',
            1: 'Fichier à valider',
            2: 'Fichier validé',
            3: 'Calcul initial en cours',
            4: 'Calcul en cours',
            5: 'Terminé'
        }
    },
    validation: {
        file: {
            max_size: 'Fichier trop lourd (Ne doit pas excéder {maxSize, number} {suffix})',
            extension: 'Extension invalide'
        },
        lowercase: 'Le champ doit contenir {nb, plural, one {une lettre minuscule} other{{nb} lettres minuscules}}',
        uppercase: 'Le champ doit contenir {nb, plural, one {une lettre majuscule} other{{nb} lettres majuscules}}',
        digits: 'Le champ doit contenir {nb, plural, one {un chiffre} other{{nb} chiffres}}',
        special_char: 'Le champ doit contenir {nb, plural, one {un caractère spécial} other{{nb} caractères spéciaux}}',
        no_accent: 'Le champ ne doit pas contenir un accent',
        min_length: 'Le champ doit contenir au moins {nb, plural, one {un caractère} other{{nb} caractères}}',
        number: {
            positive: 'Ce champ doit contenir un nombre positif',
            range: 'Ce champ doit être compris entre {min} et {max}'
        },
        max_length: 'Le champs doit contenir au plus {nb, plural, one {un caractère} other{{nb} caractères}}'
    }
})
