import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Grid } from '@material-ui/core'
import { getStyles } from 'isotope-client'

const styles = theme => getStyles({
    root: {
        padding: theme.spacing(2.5)
    }
})

const PageFragment = ({children, classes}) => {
    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>{children}</Grid>
        </Grid>
    )
}

PageFragment.propTypes = {
    children: PropTypes.node
}

export default withStyles(styles)(PageFragment)
