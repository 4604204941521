import { SubmissionError } from 'redux-form'
import middle from '../../middlewares/api/middle'
import {
    createAnimalParameters as createAnimalParametersApi,
    updateAnimalParameters as updateAnimalParametersApi,
    createGeneralParameters as createGeneralParametersApi,
    updateGeneralParameters as updateGeneralParametersApi
} from './api'
import { PARAMETERS_TYPE } from '../../appConst'
import { snackError, snackSuccess } from '../../global/snackActions'

const prefix = 'PARAMETERS/'

export const GET_PARAMETERS = `${prefix}GET_PARAMETERS`
export const GET_PARAMETERS_LOADING = `${prefix}GET_PARAMETERS_LOADING`
export const GET_PARAMETERS_ERROR = `${prefix}GET_PARAMETERS_ERROR`
export const UPDATE_PARAMETERS = `${prefix}UPDATE_PARAMETERS`
export const CREATE_PARAMETERS = `${prefix}CREATE_PARAMETERS`

export const getParameters = (year, species) => middle(GET_PARAMETERS, {
    api: '/api/admin',
    url: `${species === PARAMETERS_TYPE.GENERAL ? `/general_parameters?year=${year}`
        : `/animal_parameters/${species}?year=${year}`}`
})

export const createAnimalParameters = (values) => dispatch => {
    return createAnimalParametersApi(values)
        .then(res => {
            dispatch({
                type: CREATE_PARAMETERS,
                payload: res
            })
            return res
        })
}

export const updateAnimalParameters = (id, values) => dispatch => {
    return updateAnimalParametersApi(id, values)
        .then(res => {
            dispatch({
                type: UPDATE_PARAMETERS,
                payload: res
            })
            return res
        })
}

export const handleSubmitAnimalParameters = (values) => (dispatch) => {
    const promise = !!values.id ? updateAnimalParameters(values.id, values) : createAnimalParameters(values)
    return promise(dispatch).then(() => {
        dispatch(snackSuccess('parameters.success'))
    }, (e) => {
        dispatch(snackError())
        throw new SubmissionError(e)
    })
}

export const createGeneralParameters = (values) => (dispatch) => {
    return createGeneralParametersApi(values)
        .then((res) => {
            dispatch({
                type: CREATE_PARAMETERS,
                payload: res
            })
            return res
        })
}

export const updateGeneralParameters = (id, values) => (dispatch) => {
    return updateGeneralParametersApi(id, values)
        .then((res) => {
            dispatch({
                type: UPDATE_PARAMETERS,
                payload: res
            })
            return res
        })
}
