import { fork, takeLatest, cancel, race, cancelled } from 'redux-saga/effects'
import calculationSaga from './modules/calculation/saga'
import { LOGOUT_SUCCESS, GET_USER_SUCCESS } from 'isotope-client'

let connectedWatcher

function* onLogout() {
    yield cancel(connectedWatcher)
}

function* watchLogout() {
    yield takeLatest(LOGOUT_SUCCESS, onLogout)
}

function* connectionSaga() {
    connectedWatcher = yield fork(calculationSaga)
}

function* onLogin() {
    try {
        yield race({
            connected: connectionSaga(),
            logout: watchLogout()
        })
    } catch (error) {
        console.error('onLoginSaga', error)
    } finally {
        if (yield cancelled()) {
            /* */
        }
    }
}

function* watchLogin() {
    yield takeLatest(GET_USER_SUCCESS, onLogin)
}

/**
 * Combine les différentes sagas du projet.
 */
function* rootSaga() {
    yield fork(watchLogin)
}

export default rootSaga
