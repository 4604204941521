import { fetchFactory, postMultipart } from 'isotope-client'

const API_PREFIX ='/api'

export const updateCompensationClaimStatus = (id, values) => fetchFactory(`/compensation_claims/${id}/status`, {
    method: 'PUT',
    body: JSON.stringify(values)
}, API_PREFIX)

export const uploadCompensationRequestFile = (id, values) => postMultipart(`/compensation_claims/${id}/request_file`, {
    body: values
}, API_PREFIX)
