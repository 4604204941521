import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { Fab } from '@material-ui/core'
import { Add } from '@material-ui/icons'

const style = {
    position: 'absolute',
    right: 24,
    marginTop: 23
}

const AddButton = ({
    onClick
}) => (
    <Fab
        color="primary"
        onClick={onClick}
        style={style}
    >
        <Add/>
    </Fab>
)

AddButton.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default pure(AddButton)
