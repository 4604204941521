import { createSelector } from 'reselect'
import { formValueSelector } from 'redux-form'

const defaultFormNameSelector = (state, props) => {
    return props.form
}

/**
 * Crée un sélecteur reselect qui retourne les valeurs de certains champs d'un formulaire `redux-form`.
 *
 * Meilleure alternative à `formValueSelector` car les valeurs sont mises en cache (optimisation des renders).
 *
 * @param fields
 * @param formNameSelector
 */
export const fieldsSelector = (fields = [], formNameSelector = defaultFormNameSelector) => createSelector(
    // les sélecteurs individuels des champs
    fields.map(field => (state, props) => formValueSelector(formNameSelector(state, props))(state, field)),
    // la méthode permettant de réassembler toutes les valeurs
    (...fieldsValues) => fields.reduce((combinedValues, fieldName, index) => ({
        ...combinedValues,
        [fieldName]: fieldsValues[index]
    }), {})
)
