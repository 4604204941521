import {
    Button,
    Divider,
    IconButton,
    ListItem,
    Popover,
    Typography
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { PersonOutline } from '@material-ui/icons'
import { push } from 'connected-react-router'
import React, { PureComponent, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { createSelector } from 'reselect'
import { logOut, getStyles } from 'isotope-client'
import { connectedUserSelector } from '../modules/user/selectors'

const styles = theme => getStyles({
	buttonProfile: {
		width: 40,
		height: 40,
		boxShadow: 'none',
		padding: 0
	},
	logOutButton: {
		marginLeft: theme.spacing(1.25)
	}
})

class ProfileMenu extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            open: false
        }
    }

    closeMenu = () => {
        this.setState({
            open: false
        })
    }

	openMenu = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
            open: true
        })
	}

	handleGoToAccount = () => {
        this.closeMenu()
        this.props.goTo('/mon_compte')
    }

    handleLogOut = () => {
        this.closeMenu()
        this.props.logOut()
    }

	render() {
	    const {
            empName,
            classes
        } = this.props
        const {
	        open,
            anchorEl
        } = this.state
        return (
            <Fragment>
                <IconButton
                    color="inherit"
                    className={classes.buttonProfile}
                    onClick={this.openMenu}
                >
                    <PersonOutline />
                </IconButton>
                <Popover
                    open={open}
                    onClose={this.closeMenu}
                    anchorEl={anchorEl}
                    anchorOrigin={{vertical: 45, horizontal: 'right'}}
                >
                    <ListItem>
                        <Typography variant="body1">{empName}</Typography>
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleGoToAccount}
                        >
                            <Typography variant="body1" color="inherit">
                                <FormattedMessage id="btn.account" />
                            </Typography>
                        </Button>
                        <Button
                            className={classes.logOutButton}
                            onClick={this.handleLogOut}
                        >
                            <Typography variant="body1" color="primary">
                                <FormattedMessage id="btn.logout" />
                            </Typography>
                        </Button>
                    </ListItem>
                </Popover>
            </Fragment>
        )
    }
}

const mapStateToProps = createSelector([
    connectedUserSelector
], (user) => ({
    empName: user.empName
}))

const actions = {
	logOut,
    goTo: push
}

export default compose(
    connect(mapStateToProps, actions),
    withStyles(styles)
)(ProfileMenu)
