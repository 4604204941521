import React, { Fragment } from 'react'
import { Field } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import { pure } from 'recompose'
import { FormInput } from 'isotope-client'
import PasswordInfoText from '../../modules/password/PasswordInfoText'

const ChangePasswordFields = () => {
    return (
       <Fragment>
           <PasswordInfoText />
           <Field
               name="password"
               component={FormInput}
               label={<FormattedMessage id="login.change_password.password.label" />}
               type="password"
               inputProps={{ autoComplete: 'new-password' }}
           />
           <Field
               name="confirmation"
               component={FormInput}
               label={<FormattedMessage id="login.change_password.confirmation.label" />}
               type="password"
               inputProps={{ autoComplete: 'new-password' }}
           />
       </Fragment>
    )
}

ChangePasswordFields.propTypes = {
}

export default pure(ChangePasswordFields)
