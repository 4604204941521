import { createSelector } from 'reselect'
import { AppState } from '../../types'
import { mapLoadingValue } from '../../utils/loadingValueUtils'

export const yearsSelector = (state: AppState) => state.app.years

export const yearOptionsSelector = createSelector([
    yearsSelector
], (years) => {
    return mapLoadingValue(years, years => years.map(year => year.year))
})
