import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { createSelector } from 'reselect'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import PageFragment from '../../components/PageFragment'
import Headline from '../../components/Headline'
import { connectedUserSelector } from './selectors'
import AccountForm from './AccountForm'
import Caption from '../../components/Caption'
import { updateAccount, updatePassword } from './actions'
import { snackError, snackSuccess } from '../../global/snackActions'
import PasswordForm from './PasswordForm'
import { passwordConfigSelector } from '../../properties/selectors'
import PasswordInfoText from '../password/PasswordInfoText'
import PasswordConstraintsContext from '../password/PasswordConstraintsContext'

const styles = theme => getStyles({
    passwordTitle: {
        marginTop: theme.spacing(4)
    }
})

class AccountPage extends PureComponent {
    handleUpdateAccountInfo = (values) => {
        return this.props.updateAccount(values)
            .then(() => {
                this.props.snackSuccess('snackMessage.saveOk')
            }, (e) => {
                this.props.snackError()
                throw new SubmissionError(e)
            })
    }
    handleUpdatePassword = (values) => {
        return this.props.updatePassword(values, this.props.accountInfo.email)
            .then(() => {
                this.props.snackSuccess('snackMessage.saveOk')
            }, (e) => {
                this.props.snackError()
                throw new SubmissionError(e)
            })
    }
    render() {
        const {
            accountInfo,
            passwordConfig,
            classes
        } = this.props
        return (
            <PageFragment>
                <Caption>
                    <FormattedMessage id="form.needInformation" />
                </Caption>
                <Headline>
                    <FormattedMessage id="title.personalInformation" />
                </Headline>
                <AccountForm
                    form="AccountForm"
                    initialValues={accountInfo}
                    onUpdateAccountInfo={this.handleUpdateAccountInfo}
                />
                <Headline className={classes.passwordTitle}>
                    <FormattedMessage id="title.newPassword" />
                </Headline>
                <PasswordConstraintsContext.Provider value={passwordConfig}>
                    <PasswordInfoText />
                    <PasswordForm
                        form="PasswordForm"
                        onUpdatePassword={this.handleUpdatePassword}
                    />
                </PasswordConstraintsContext.Provider>
            </PageFragment>
        )
    }
}

const makeMapStateToProps = () => {
    const accountInitialValuesSelector = createSelector([
        connectedUserSelector
    ], (user) => {
        const {
            empName,
            instCode,
            email
        } = user
        return {
            empName,
            instCode,
            email
        }
    })
    return createSelector([
        accountInitialValuesSelector,
        passwordConfigSelector
    ], (accountInfo, passwordConfig) => ({
        accountInfo,
        passwordConfig
    }))
}

const mappedActions = {
    updateAccount,
    snackError,
    snackSuccess,
    updatePassword
}

export default compose(
    withStyles(styles),
    connect(makeMapStateToProps, mappedActions)
)(AccountPage)
