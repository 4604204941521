import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Typography
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { pure } from 'recompose'
import { FormattedMessage } from 'react-intl'

const InformationDialog = ({
    onClose,
    title,
    declineLabel,
    children,
    ...otherProps
}) => {
    return (
        <Dialog
            disableEscapeKeyDown
            {...otherProps}
        >
            <DialogTitle disableTypography>
                <Typography variant="h5">{title}</Typography>
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText color="textPrimary">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary"
                    variant="contained"
                >
                    {declineLabel || <FormattedMessage id="btn.close"/>}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
InformationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
	title: PropTypes.node.isRequired,
    declineLabel: PropTypes.node,
}

export default pure(InformationDialog)
