import UsersPage from './modules/users/UsersPage'
import AddUserPage from './modules/users/AddUserPage'
import CompensationClaimsPage from './modules/compensation-claim/CompensationClaimsPage'
import CalculationPage from './modules/calculation/CalculationPage'
import SubsidyFiles from './modules/subsidy-files/SubsidyFilesPage'
import ParametersPage from './modules/parameters/ParametersPage'
import { isAdmin } from './modules/user/rights'
import AccountPage from './modules/user/AccountPage'

export const defaultRoute = {
    title: 'compensation_claim',
    path: '/suivi_dossiers',
    component: CompensationClaimsPage
}

export default [defaultRoute, {
    title: 'calculation',
    path: '/calcul',
    access: isAdmin,
    component: CalculationPage
}, {
    title: 'subsidy_files',
    path: '/donnees_subventions',
    access: isAdmin,
    component: SubsidyFiles
}, {
    title: 'users',
    path: '/utilisateurs',
    access: isAdmin,
    component: UsersPage
}, {
    title: 'users',
    path: '/utilisateurs/ajout',
    access: isAdmin,
    component: AddUserPage
}, {
    title: 'parameters',
    path: '/parametres',
    access: isAdmin,
    component: ParametersPage
}, {
    title: 'my_account',
    path: '/mon_compte',
    component: AccountPage
}]
